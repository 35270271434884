import React, { useEffect, useState } from "react";
import { Focus } from "../component/custom/Landing/Focus";
import { Trending } from "../component/custom/Landing/Trending";
import { OnlineSupport } from "../component/custom/Landing/OnlineSupport";
import { hideSider } from "../features/sider/siderSlice";
import { useDispatch } from "react-redux/es/exports";
import {
  hideMemberHeader,
  updateHaveNewVideo,
} from "../features/member/memberSlice";
import "react-multi-carousel/lib/styles.css";
import data from "../data/fake_data";
import { isLoggedIn } from "../utils/Utils";
import {
  getUserData,
  getMemberTelPhone,
  logout,
  getCurrentYear,
} from "../utils/Utils";
import {
  ClientAPIClient,
  AuthenticateClient,
} from "../service/web-api-client.ts";
import { useSelector } from "react-redux";
import { notification, Spin } from "antd";
import { useNavigate } from "react-router-dom";

const Landing = (props) => {
  const dispatch = useDispatch();
  const [isLoading, set_isLoading] = useState(false);
  const [haveUser, set_haveUser] = useState(false);
  const [videoList, set_videoList] = useState([]);
  const [dataState, set_dataState] = useState(null);
  const lang = useSelector((state) => state.lang.value);
  const nav = useNavigate();

  useEffect(() => {
    dispatch(hideSider());
    dispatch(hideMemberHeader());
    set_isLoading(true);
    (async () => {
      try {
        const user = getUserData();
        if (!user) {
          set_haveUser(false);
        }
        if (user) {
          try {
            const authenticateClient = new AuthenticateClient(
              process.env.REACT_APP_API_BASE_URL
            );
            const result = await authenticateClient.refreshToken({
              accessToken: localStorage.getItem("accessToken"),
              refreshToken: localStorage.getItem("refreshToken"),
              userName: getMemberTelPhone(),
            });
            // console.log(result);
            localStorage.setItem("accessToken", result.accessToken);
            localStorage.setItem("refreshToken", result.refreshToken);
            const ClientAPI_Client = new ClientAPIClient(
              process.env.REACT_APP_API_BASE_URL
            );
            const result_ClientAPI =
              await ClientAPI_Client.getVideoAndVideoCat();
            //console.log("result_ClientAPI", result_ClientAPI);
            dispatch(
              updateHaveNewVideo(
                result_ClientAPI.filter((e) => e.progress == "New").length > 0
              )
            );
            set_haveUser(true);
          } catch (e) {
            console.log(e);
            await logout();
            set_haveUser(false);
            // return nav("/Login");
          }
        }
        const client = new ClientAPIClient(process.env.REACT_APP_API_BASE_URL);
        const result = await client.getWebContentList();
        // console.log(result);
        set_dataState(
          result.sort(
            (a, b) => Number(new Date(b.creDt)) - Number(new Date(a.creDt))
          )
        );
        const videoResult = await client.getPublicVideoList();
        set_videoList(videoResult);
        set_isLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        {dataState && (
          <div>
            <OnlineSupport haveUser={haveUser} videoList={videoList} />
          </div>
        )}

        {dataState && (
          <div>
            <Trending
              trendings={dataState
                .filter((obj) => obj.type === "Trending")
                .slice(0, 3)}
            />
          </div>
        )}
        {dataState && (
          <div>
            <Focus
              focuses={dataState
                .filter((obj) => obj.type === "Focus")
                .slice(0, 3)}
            />
          </div>
        )}
      </Spin>
    </React.Fragment>
  );
};

export default Landing;
