import { Row, Col, Input, Checkbox, Button, notification } from "antd";
import Carousel from "react-multi-carousel";

import { useSelector } from "react-redux/es/exports";
import styles from "./landing.module.scss";
import { landingMessage } from "../../../message/message";
import { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import { useNavigate, Link } from "react-router-dom";
import {
  AuthenticateClient,
  CurrentLoginUserClient,
} from "../../../service/web-api-client.ts";
import { useCookies } from "react-cookie";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { getUserData } from "../../../utils/Utils";
import { createCuePoint } from "../../../utils/Utils";

export const OnlineSupport = (props) => {
  const { haveUser, videoList } = props;
  const [loginLoading, set_loginLoading] = useState(false);
  const [cuepoint, set_cuepoint] = useState({
    cp0: false,
    cp1: false,
    cp2: false,
    cp3: false,
    cp4: false,
    cp5: false,
    cp6: false,
    cp7: false,
    cp8: false,
    cp9: false,
  });

  const [isFinish, set_isFinish] = useState(false);
  const [cookie, set_cookie] = useCookies(["rememberUserName"]);

  let navigate = useNavigate();
  const lang = useSelector((state) => state.lang.value); // use store state
  const [dataState, set_dataState] = useState({
    userName: cookie.rememberUserName || "",
    password: cookie.rememberPassword || "",
    captcha: "",
    rememberMe: true,
  });
  const vimeoRef = useRef(null);

  const changeHandler = (field, value) => {
    set_dataState({ ...dataState, [field]: value });
  };

  const loginHandler = () => {
    set_loginLoading(true);
    if (dataState.userName.length == 0) {
      set_loginLoading(false);
      return notification.warning({
        message: landingMessage.EMPTY_USERNAME[lang],
      });
    }
    if (dataState.password.length == 0) {
      set_loginLoading(false);
      return notification.warning({
        message: landingMessage.EMPTY_PASSWORD[lang],
      });
    }
    if (!validateCaptcha(dataState.captcha)) {
      set_loginLoading(false);
      return notification.warning({
        message: landingMessage.WRONG_CAPTCHA[lang],
      });
    }
    (async () => {
      try {
        const client = new AuthenticateClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const result = await client.memberLogin(dataState);

        if (!result) {
          return notification.error({
            message: landingMessage.WRONG_LOGIN_INFO[lang],
          });
        }
        if (result.message) {
          return notification.error({
            message: result.message,
          });
        }

        localStorage.setItem("refreshToken", result.refreshToken);
        localStorage.setItem("accessToken", result.token);
        const currentLoginUserClient = new CurrentLoginUserClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const userData = await currentLoginUserClient.getCurrentMember();

        localStorage.setItem("userData", JSON.stringify(userData));

        if (dataState.rememberMe) {
          set_cookie("rememberUserName", dataState.userName, { path: "/" });
          set_cookie("rememberPassword", dataState.password, { path: "/" });
        } else {
          set_cookie("rememberUserName", "", { path: "/" });
          set_cookie("rememberPassword", "", { path: "/" });
        }
        navigate("/Home");
      } catch (e) {
        console.log(e);
        notification.error({
          message: landingMessage.ERROR[lang],
        });
      } finally {
        set_loginLoading(false);
      }
    })();
    //
  };

  useEffect(() => {
    if (!haveUser) {
      loadCaptchaEnginge(6);
    }
    if (document.getElementById("reload_href")) {
      document.getElementById("reload_href").innerHTML =
        landingMessage.RELOAD_CAPTCHA[lang];
    }

    // copy from here
  }, [vimeoRef, lang]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={styles["online-support-session"]}>
      <Row gutter={16}>
        <Col
          lg={12}
          md={24}
          sm={24}
          xs={24}
          className={styles["online-support-login-session"]}
        >
          <div className={styles["online-support-project-name"]}>
            {landingMessage.PROJECT_NAME[lang]}
          </div>
          <div className={styles["online-support-title"]}>
            {landingMessage.TITLE[lang]}
          </div>

          <div className={styles["online-support-description"]}>
            {landingMessage.DESCRIPTION1[lang]}
          </div>
          <div className={styles["online-support-description"]}>
            {landingMessage.DESCRIPTION2[lang]}
          </div>

          {haveUser ? (
            <>
              <div className={styles["online-support-logged-in-title"]}>
                {landingMessage.WELCOME_BACK[lang]}
                <br></br>
                {lang == "en"
                  ? `${getUserData().firstNameEn} ${getUserData().lastNameEn}`
                  : getUserData().nameCn}
              </div>
              <Row
                align="middle"
                justify="space-between"
                className={styles["online-support-logged-in-nav-buttons"]}
              >
                <Col
                  lg={8}
                  sm={24}
                  xs={24}
                  className={styles["online-support-logged-in-nav-button"]}
                >
                  <Link to="/Home">
                    <Button type={"primary"}>
                      {landingMessage.MEMBER_HOME_NAV[lang]}
                    </Button>
                  </Link>
                </Col>
                <Col
                  lg={8}
                  sm={24}
                  xs={24}
                  className={styles["online-support-logged-in-nav-button"]}
                >
                  <Link to="/Profile">
                    <Button type={"primary"}>
                      {landingMessage.MEMBER_INFO_NAV[lang]}
                    </Button>
                  </Link>
                </Col>
                <Col
                  lg={8}
                  sm={24}
                  xs={24}
                  className={styles["online-support-logged-in-nav-button"]}
                >
                  <Link to="/Video">
                    <Button type={"primary"}>
                      {landingMessage.MEMBER_VIDEO_NAV[lang]}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className={styles["online-support-login-input"]}>
                <Input
                  size="large"
                  placeholder={landingMessage.LOGIN_PLACEHOLDER[lang]}
                  value={dataState.userName}
                  onChange={(e) => {
                    changeHandler("userName", e.target.value);
                  }}
                />
              </div>
              <div className={styles["online-support-password-input"]}>
                <Input.Password
                  size="large"
                  placeholder={landingMessage.PASSWORD_PLACEHOLDER[lang]}
                  value={dataState.password}
                  onChange={(e) => {
                    changeHandler("password", e.target.value);
                  }}
                />
              </div>

              <div className={styles["online-support-remember-me-checkbox"]}>
                <Row justify="space-between">
                  <Col>
                    <div className={styles["online-support-captcha"]}>
                      <LoadCanvasTemplate />
                    </div>
                  </Col>
                  <Col>
                    <Checkbox
                      defaultChecked={dataState.rememberMe}
                      onChange={(e) => {
                        changeHandler("rememberMe", e.value);
                      }}
                    >
                      {landingMessage.REMEMBER_ME[lang]}
                    </Checkbox>
                  </Col>
                </Row>
                <Row justify="space-between" align={"middle"}>
                  <Col>
                    <div>
                      <div className={styles["online-support-captcha-input"]}>
                        <Input
                          size="large"
                          placeholder={landingMessage.CAPTCHA_PLACEHOLDER[lang]}
                          value={dataState.captcha}
                          onChange={(e) => {
                            changeHandler("captcha", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      onClick={(e) => {
                        loginHandler();
                      }}
                      disabled={loginLoading}
                      loading={loginLoading}
                    >
                      {landingMessage.LOGIN[lang]}
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Col>
        <Col lg={12} md={24} sm={24} xs={24} className="align-center">
          {/* <Carousel swipeable={true} responsive={responsive}>
            {videoList.map((item, idx) => {
              // return <div className="vimeo" id={`intro-vimeo-${idx}`} />;
              return (
                <div>
                  <VimeoPlayer
                    id={idx}
                    item={item}
                    option={{
                      id: item.videoUrl,
                      width: "100%",
                      height: "100%",
                      // allowfullscreen: true,
                      frameborder: "0",
                      title: false,
                      portrait: false,
                      byline: false,
                      controls: false,
                      autoplay: false,
                      // Control is currently not working for Basic Plan, more:
                      // https://github.com/vimeo/player.js#embed-options
                    }}
                    title={
                      lang == "en"
                        ? item.videoNameEn || "titleEn"
                        : item.videoName || "titleZh"
                    }
                  />
                </div>
              );
            })}
          </Carousel> */}
          <iframe
            // width="560"
            // height="315"
            src="https://www.youtube.com/embed/videoseries?list=PLRjo3rCJ_H0G7VDTHOf7KFkg5GmdAPQCB"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};
