import { Row, Col } from "antd";
import { Link } from "react-router-dom";
// import { VimeoThumbnail } from "../../common/VimeoThumbnail";
// import { memberHomeListingPage } from "../../../message/message";
import styles from "../MemberModule/member.module.scss";
import { VideoItem } from "./VideoItem";
import { memberHomeListingPage } from "../../../message/message";
export const MemberHomeListingPage = (props) => {
  const { Lang, Title, IsCompleted, videoList, PageCode } = props;
  const ShowAll = (Code) => {
    return "/Video/" + Code;
  };
  return (
    <>
      <div>
        <Row>
          <Col xl={18} lg={18} md={18} sm={18} xs={18}>
            <div className={styles["label-title"]}>{Title}</div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
            <div className={styles["label-show-title"]}>
              <Link to={ShowAll(PageCode)}>
                {memberHomeListingPage.ShowAll[Lang]}
              </Link>
            </div>
          </Col>
        </Row>
        {videoList.length > 0 ? (
          <Row>
            {videoList.splice(0, 4).map((item, idx) => (
              <VideoItem
                videoItem={item}
                Lang={Lang}
                VimeoId={item.VimeoId}
                IsCompleted={IsCompleted}
                IsCat={item.type == "video cat"}
              />
            ))}
          </Row>
        ) : (
          <div className={styles["no-video-message"]}>
            {memberHomeListingPage.NO_VIDEO[Lang]}
          </div>
        )}
        {/* {videoList.length == 0 && (
          
        )} */}
      </div>
    </>
  );
};
