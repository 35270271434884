import { Row, Col, notification, Button, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  landingMessage,
  memberHomeListingPage,
  GeneralMessage,
} from "../../../message/message";
import styles from "../MemberModule/member.module.scss";
import { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import { VimeoThumbnail } from "../../common/VimeoThumbnail";
import CommentModalContent from "./CommentModalContent";

import { useDispatch } from "react-redux";
import { showSider } from "../../../features/sider/siderSlice";
import { changeActiveItem } from "../../../features/sider/siderSlice";
import {
  createCuePoint,
  getUserData,
  getCurrentYear,
  getCurrentMonth,
} from "../../../utils/Utils";

import {
  WhatsappClient,
  ClientAPIClient,
} from "../../../service/web-api-client.ts";
export const VideoViewPage = (props) => {
  const {
    lang,
    VimeoId,
    IsCat = false,
    videoModel,
    videoList,
    videoParam,
  } = props;
  // const [cuePoints, set_cuePoints] = useState([
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  // ]);
  const cuePoints = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  const QC = [false];

  const [completenessMarked, set_completenessMarked] = useState(false);

  const dispatch = useDispatch();
  const [modalState, set_modalState] = useState({ content: "" });
  const [modalVisible, set_modalVisible] = useState(false);
  const toggleModal = () => {
    set_modalVisible(!modalVisible);
  };

  const openModal = () => {
    set_modalState({ content: "" });
    toggleModal();
  };

  const confirmHandler = () => {
    // console.log("Save Comment: ", modalState.content);
    (async () => {
      try {
        const whatsappClient = new WhatsappClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const result = await whatsappClient.createWhatsapp({
          memberSysId: getUserData().sysId,
          telPhone: getUserData().telPhone,
          message: `[${videoModel.videoNum}]${modalState.content}`,
        });
        notification.success({
          message: GeneralMessage.SUCCESSFULLY_COMMENT[lang],
        });
      } catch (e) {
        console.log(e);
        notification.error({
          message: GeneralMessage.FAIL_COMMENT[lang],
        });
      }
    })();
    toggleModal();
  };

  const changeHandler = (field, value) => {
    set_modalState({ ...modalState, [field]: value });
  };

  const messageButtonClickHandler = () => {
    if (getUserData().isUseWhatsapp) {
      window
        .open(
          `http://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=[${videoModel.videoNum}]`,
          "_blank"
        )
        .focus();
    } else {
      openModal();
    }
  };

  const questionButtonClickHandler = () => {
    (async () => {
      const clientAPIClient = new ClientAPIClient(
        process.env.REACT_APP_API_BASE_URL
      );
      await clientAPIClient.updateQuestionnaireCompleteness({
        videoId: videoParam,
        isQuestionnaireComplete: true,
      });
      window.open(videoModel.questionnaire, "_blank").focus();
    })();
  };

  const rowRedirect = "/ViewVideo/";
  useEffect(() => {
    dispatch(showSider());
    dispatch(changeActiveItem()); // refer to src/component/common/CustomSider.js
    // const vimeo = vimeoRef.current;
    // console.log(vimeo);
    const options = {
      id: VimeoId,
      width: "100%",
      height: "100%",
      // allowfullscreen: true,
      frameborder: "0",
      title: false,
      portrait: false,
      byline: false,
      // controls: false,
      autoplay: true,
      // Control is currently not working for Basic Plan, more:
      // https://github.com/vimeo/player.js#embed-options
    };
    const player = new Player("intro-vimeo", options);
    player.getDuration().then(function (duration) {
      createCuePoint(lang, player, duration);
    });
    player.on("cuepoint", async function (e) {
      // trigger -> createVideoMemberProgress
      // console.log(e.data.cuePoint);
      cuePoints[e.data.cuePoint] = true;
      // console.log(cuePoints);
      const clientAPIClient = new ClientAPIClient(
        process.env.REACT_APP_API_BASE_URL
      );

      let isComplete = false;
      if (cuePoints.filter((obj) => !obj).length < 1) {
        isComplete = true;
      }
      if (isComplete) {
        await clientAPIClient.updateVideoCompleteness({
          videoId: videoParam,
          isComplete,
          value: videoModel.completeTimes + 1,
          year: getCurrentYear(),
          month: getCurrentMonth(),
        });
        await clientAPIClient.updateVideoCompleteness({
          videoId: videoParam,
          isComplete: false,
          value: videoModel.incompleteTimes,
          year: getCurrentYear(),
          month: getCurrentMonth(),
        });
      } else {
        await clientAPIClient.updateVideoCompleteness({
          videoId: videoParam,
          isComplete,
          value: videoModel.incompleteTimes + 1,
          year: getCurrentYear(),
          month: getCurrentMonth(),
        });
      }
    });

    // return () => {
    //   let isComplete = false;
    //   let isQC = false;
    //   if (cuePoints.filter((obj) => !obj).length < 1) {
    //     isComplete = true;
    //   }
    //   if (QC.filter((obj) => !obj).length == 0) {
    //     isQC = true;
    //   }
    //   console.log(isQC);
    //   (async () => {
    //     try {
    //       const clientAPIClient = new ClientAPIClient(
    //         process.env.REACT_APP_API_BASE_URL
    //       );
    //       await clientAPIClient.updateVideoCompleteness({
    //         videoId: videoParam,
    //         isComplete,
    //       });
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   })();
    // };
  }, [videoParam]);

  const navigate = useNavigate();
  const ToVideo = (VideoVimeoId, VimeoCatId = null) => {
    if (VimeoCatId) {
      return rowRedirect + VimeoCatId + "/" + VideoVimeoId;
    } else {
      return rowRedirect + VideoVimeoId;
    }
  };
  const GetName = (videoName) => {
    // if (videoName.length > 18) {
    //   if (lang == "en") {
    //     return videoName.substr(0, 20) + "...";
    //   } else {
    //     return videoName.substr(0, 8) + "...";
    //   }
    // } else {
    //   return videoName;
    // }
    return videoName;
  };
  return (
    <>
      <div
        className={
          IsCat
            ? styles["viewVideo-Cat-profile-background"]
            : styles["viewVideo-profile-background"]
        }
      >
        <Row style={{ paddingBottom: "10px" }}>
          {IsCat && videoList ? (
            <>
              <Col
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="align-center"
              >
                <div className="vimeo" id="intro-vimeo"></div>
                <Row>
                  <Col xl={16} lg={16} md={16} sm={16} xs={24}>
                    <div className={styles["video-name"]}>
                      {lang == "en"
                        ? videoModel.videoNameEn
                        : videoModel.videoName}
                    </div>
                    {/* <div className={styles["video-desc-title"]}>
                      {memberHomeListingPage.BriefIntroduction[lang]}
                    </div>
                    <div className={styles["video-desc"]}>
                      {lang == "en"
                        ? videoModel.vedioDescriptionEn
                        : videoModel.videoDescription}
                    </div> */}
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                    <Button
                      className={styles["viewVideo-button-watch-style"]}
                      onClick={() => {
                        messageButtonClickHandler();
                      }}
                    >
                      {memberHomeListingPage.LeaveMessage[lang]}
                    </Button>
                    {videoModel.questionnaire && (
                      <Button
                        onClick={() => {
                          questionButtonClickHandler();
                        }}
                        className={styles["viewVideo-button-watch-style"]}
                      >
                        {memberHomeListingPage.Questionnaire[lang]}
                      </Button>
                    )}
                  </Col>
                  <Col span={24}>
                    <div className={styles["video-desc-title"]}>
                      {memberHomeListingPage.BriefIntroduction[lang]}
                    </div>
                    <div className={styles["video-desc"]}>
                      {lang == "en"
                        ? videoModel.vedioDescriptionEn
                        : videoModel.videoDescription}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                className={styles["videoList-div-style"]}
              >
                <div className={styles["label-title"]}>
                  {lang == "en"
                    ? videoList.videoCategoryNameEn
                    : videoList.videoCategoryName}
                </div>
                {videoList.videos.map((item, idx) => (
                  <>
                    {(idx == 0 || videoList.videos[idx - 1].isComplete) && (
                      <Row
                        style={{
                          marginBottom: "2vh",
                          cursor: "pointer",
                        }}
                      >
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div
                            onClick={() => {
                              window.location.href = ToVideo(
                                item.sysId,
                                videoList.sysId
                              );
                            }}
                          >
                            {videoModel.sysId == item.sysId && (
                              <div
                                className={
                                  styles["CurrentViewVideo-div-black-style"]
                                }
                              ></div>
                            )}
                            <VimeoThumbnail
                              vimeoId={item.VimeoId}
                              size={"large"}
                            />
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className={styles["videoList-div-title"]}>
                            <span
                              title={
                                lang == "en" ? item.videoNameEn : item.videoName
                              }
                            >
                              {GetName(
                                lang == "en" ? item.videoNameEn : item.videoName
                              )}
                            </span>
                          </div>
                          <div className={styles["videoList-div-creDt"]}>
                            {item.creDt}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </>
                ))}
              </Col>
            </>
          ) : (
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="align-center"
            >
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="vimeo" id="intro-vimeo"></div>
                </Col>
              </Row>
              <Row>
                <Col xl={16} lg={16} md={16} sm={16} xs={24}>
                  <div className={styles["video-name"]}>
                    {lang == "en"
                      ? videoModel.videoNameEn
                      : videoModel.videoName}
                  </div>
                </Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                  <Button
                    className={styles["viewVideo-button-watch-style"]}
                    onClick={() => {
                      messageButtonClickHandler();
                    }}
                  >
                    {memberHomeListingPage.LeaveMessage[lang]}
                  </Button>
                  {videoModel.questionnaire && (
                    <Button
                      onClick={() => {
                        // window.open(videoModel.questionnaire, "_blank").focus();
                        questionButtonClickHandler();
                      }}
                      className={styles["viewVideo-button-watch-style"]}
                    >
                      {memberHomeListingPage.Questionnaire[lang]}
                    </Button>
                  )}
                </Col>
                <Col span={24}>
                  <div className={styles["video-desc-title"]}>
                    {memberHomeListingPage.BriefIntroduction[lang]}
                  </div>
                  <div className={styles["video-desc"]}>
                    {lang == "en"
                      ? videoModel.vedioDescriptionEn
                      : videoModel.videoDescription}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
      <Modal
        title={memberHomeListingPage.LeaveMessage[lang]}
        visible={modalVisible}
        onOk={() => {
          confirmHandler();
        }}
        onCancel={() => {
          toggleModal();
        }}
        cancelText={GeneralMessage.CANCEL[lang]}
        okText={GeneralMessage.OKAY[lang]}
      >
        <CommentModalContent
          content={modalState.content}
          changeHandler={changeHandler}
        />
      </Modal>
    </>
  );
};
