import { siderMessage } from "../../message/message";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const CustomSider = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const sider = useSelector((state) => state.sider);

  return (
    <div className="sider-items">
      <Link to="/Home">
        <div
          className={`sider-item ${
            sider.activeItem === "HOME" ? "sider-item-active" : null
          }`}
        >
          {siderMessage.HOME[lang]}
        </div>
      </Link>
      <Link to="/Profile">
        <div
          className={`sider-item ${
            sider.activeItem === "MY_PROFILE" ? "sider-item-active" : null
          }`}
        >
          {siderMessage.MY_PROFILE[lang]}
        </div>
      </Link>
      <Link to="/Video">
        <div
          className={`sider-item ${
            sider.activeItem === "MY_VIDEO" ? "sider-item-active" : null
          }`}
        >
          {siderMessage.MY_VIDEO[lang]}
        </div>
      </Link>
    </div>
  );
};

export default CustomSider;
