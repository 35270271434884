import { useParams } from "react-router-dom";
import { ClientAPIClient } from "../../service/web-api-client.ts";
import { useEffect, useState } from "react";
import WebContentDetail from "../../component/custom/WebContent/WebContentDetail";
import { notification, Spin } from "antd";
const FocusDetail = (props) => {
  const { web_content_id } = useParams();
  const [isLoading, set_isLoading] = useState(false);
  const [webContentState, set_webContentState] = useState(null);
  useEffect(() => {
    set_isLoading(true);
    (async () => {
      try {
        const client = new ClientAPIClient(process.env.REACT_APP_API_BASE_URL);
        const result = await client.getWebContentList();
        set_webContentState(result);
        set_isLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <>
      <Spin spinning={isLoading}>
        {webContentState && (
          <WebContentDetail
            webContent={webContentState.find(
              (obj) => obj.sysId == web_content_id
            )}
            type={"Focus"}
          />
        )}
      </Spin>
    </>
  );
};

export default FocusDetail;
