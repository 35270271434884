import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import styles from "./webContent.module.scss";
import { landingMessage } from "../../../message/message";
import { Link } from "react-router-dom";
import default_img from "../../../assets/img/web_content_default.png";

const WebContentListing = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const { webContents, type } = props;
  return (
    <>
      {webContents.length > 0 && (
        <div className={styles["web-content-items"]}>
          {webContents.map((webContent) => (
            <div className={styles["web-content-item"]}>
              <Link to={`/${type}/${webContent.sysId}`}>
                <div className={styles["web-content-list-learn-more"]}>
                  <div>{landingMessage.LEARN_MORE[lang]}</div>
                </div>
                <Row justify="space-around" align="middle">
                  <Col lg={8} sm={24} xs={24}>
                    <div>
                      <img
                        src={webContent.url ? webContent.url : default_img}
                        className={styles["web-content-list-img"]}
                      />
                    </div>
                  </Col>
                  <Col lg={10} sm={24} xs={24}>
                    <div className={styles["web-content-list-title"]}>
                      {lang == "en" ? webContent.titleEn : webContent.title}
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          ))}
        </div>
      )}
      {webContents.length == 0 && (
        <div className={styles["no-web-content"]}>
          {type == "Focus"
            ? landingMessage.NO_FOCUS[lang]
            : landingMessage.NO_TRENDING[lang]}
        </div>
      )}
    </>
  );
};

export default WebContentListing;
