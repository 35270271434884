import { Row, Col } from "antd";
// import { VimeoThumbnail } from "../../common/VimeoThumbnail";
// import { memberHomeListingPage } from "../../../message/message";
import styles from "../MemberModule/member.module.scss";
import { VideoItem } from "../MemberHome/VideoItem";
import { memberHomeListingPage } from "../../../message/message";
export const KeepVideo = (props) => {
  const { Lang, videoList } = props;
  return (
    <>
      <div className={styles["online-support-session"]}>
        <Row>
          {/* <Col>
            <div className={styles["label-title"]} >繼續觀看Page</div>
            </Col> */}
        </Row>
        {videoList.length > 0 && (
          <Row>
            {videoList.map((item, idx) => (
              <VideoItem
                videoItem={item}
                Lang={Lang}
                VimeoId={item.VimeoId}
                IsCat={item.type == "video cat"}
              />
            ))}
          </Row>
        )}
        {videoList.length == 0 && (
          <div className={styles["no-video-message"]}>
            {memberHomeListingPage.NO_VIDEO[Lang]}
          </div>
        )}
      </div>
    </>
  );
};
