import { Button, Row, Col, Card } from "antd";
import Carousel from "react-multi-carousel";
import styles from "./landing.module.scss";
import { landingMessage } from "../../../message/message";
import { useSelector } from "react-redux/es/exports";
import { Link, useNavigate } from "react-router-dom";
import default_img from "../../../assets/img/nad-logo.png";

// export const Trending = (props) => {
//   const { trendings } = props;
//   const top_trending = trendings[0] || null;
//   const secondary_trending = trendings[1] || null;
//   const third_trending = trendings[2] || null;
//   const lang = useSelector((state) => state.lang.value);

//   const nav = useNavigate();

//   return (
//     <div className={styles["trending-session"]}>
//       <div className={styles["more-button"]}>
//         <Link to="/Trending">
//           <Button type="danger" shape="round">
//             {landingMessage.LEARN_MORE[lang]}
//           </Button>
//         </Link>
//       </div>
//       <div className={styles["trending-session-title"]}>
//         {landingMessage.TRENDING_TITLE[lang]}
//       </div>
//       {top_trending && (
//         <>
//           <div>
//             <Row justify="space-evenly">
//               <Col xl={11} lg={24} md={24} sm={24} xs={24}>
//                 <div
//                   className={styles["top-card"]}
//                   style={{
//                     backgroundImage: `linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)),url("${top_trending.url}")`,
//                   }}
//                   onClick={() => {
//                     nav(`/Trending/${top_trending.sysId}`);
//                   }}
//                 >
//                   <img
//                     src={top_trending.url ? top_trending.url : default_img}
//                     style={{ visibility: "hidden" }}
//                   />
//                   <div className={styles["top-card-text"]}>
//                     <div className={styles["top-card-title"]}>
//                       {lang == "en" ? top_trending.titleEn : top_trending.title}
//                     </div>
//                     <div className={styles["top-card-desc"]}>
//                       {landingMessage.CARD_DESCRIPTION[lang]}
//                     </div>
//                   </div>
//                 </div>
//               </Col>

//               <Col xl={11} lg={24} md={24} sm={24} xs={24}>
//                 {secondary_trending && (
//                   <div
//                     className={styles["secondary-card"]}
//                     onClick={() => {
//                       nav(`/Trending/${secondary_trending.sysId}`);
//                     }}
//                   >
//                     <Row align="middle" justify="space-evenly" gutter="16">
//                       <Col lg={10} md={10} sm={14} xs={20}>
//                         <img
//                           className={styles["secondary-card-img"]}
//                           src={
//                             secondary_trending.url
//                               ? secondary_trending.url
//                               : default_img
//                           }
//                         />
//                       </Col>
//                       <Col lg={14} md={14} sm={24} xs={24}>
//                         <div className={styles["secondary-card-text"]}>
//                           <div className={styles["secondary-card-title"]}>
//                             {lang == "en"
//                               ? secondary_trending.titleEn
//                               : secondary_trending.title}
//                           </div>
//                           <div className={styles["secondary-card-desc"]}>
//                             {landingMessage.CARD_DESCRIPTION[lang]}
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>
//                 )}
//                 {third_trending && (
//                   <div
//                     className={styles["third-card"]}
//                     onClick={() => {
//                       nav(`/Trending/${third_trending.sysId}`);
//                     }}
//                   >
//                     <Row align="middle" justify="space-evenly" gutter="16">
//                       <Col lg={10} md={10} sm={14} xs={20}>
//                         <img
//                           className={styles["third-card-img"]}
//                           src={
//                             third_trending.url
//                               ? third_trending.url
//                               : default_img
//                           }
//                         />
//                       </Col>
//                       <Col lg={14} md={14} sm={24} xs={24}>
//                         <div className={styles["third-card-text"]}>
//                           <div className={styles["third-card-title"]}>
//                             {lang == "en"
//                               ? third_trending.titleEn
//                               : third_trending.title}
//                           </div>
//                           <div className={styles["third-card-desc"]}>
//                             {landingMessage.CARD_DESCRIPTION[lang]}
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>
//                 )}
//               </Col>
//             </Row>
//           </div>
//         </>
//       )}
//       {trendings.length == 0 && (
//         <div className={styles["no-article-message"]}>
//           {landingMessage.NO_TRENDING[lang]}
//         </div>
//       )}
//     </div>
//   );
// };

export const Trending = (props) => {
  const { trendings } = props;
  const lang = useSelector((state) => state.lang.value);

  const nav = useNavigate();
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };
  console.log(trendings.length);
  return (
    <div className={styles["trending-session"]}>
      <div className={styles["more-button"]}>
        <Link to="/Trending">
          <Button type="danger" shape="round">
            {landingMessage.LEARN_MORE[lang]}
          </Button>
        </Link>
      </div>
      <div className={styles["trending-session-title"]}>
        {landingMessage.TRENDING_TITLE[lang]}
      </div>

      {trendings.length > 0 && (
        <div
          style={{
            margin:
              trendings.length == 1 && window.innerWidth > 500 ? "auto" : "0px",
            width:
              trendings.length == 1 && window.innerWidth > 500 ? "50%" : "100%",
          }}
        >
          <div style={{ minWidth: "300px" }}>
            <Carousel
              swipeable={true}
              responsive={{
                superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: trendings.length > 3 ? 3 : trendings.length,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: trendings.length > 3 ? 3 : trendings.length,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: trendings.length > 2 ? 2 : trendings.length,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: trendings.length > 1 ? 1 : trendings.length,
                },
              }}
            >
              {trendings.map((item) => {
                return (
                  <div
                    className={styles["carousel-container"]}
                    // style={{ textAlign: "center" }}
                  >
                    <Card
                      hoverable
                      className={styles["carousel-card"]}
                      // style={{ maxWidth: "50%" }}
                      cover={
                        <img
                          alt=""
                          src={item.url ? item.url : default_img}
                          width="auto"
                          height="auto"
                        />
                      }
                      onClick={() => {
                        nav(`/Trending/${item.sysId}`);
                      }}
                    >
                      <h2>{lang == "en" ? item.titleEn : item.title}</h2>
                      <p>{landingMessage.CARD_DESCRIPTION[lang]}</p>
                    </Card>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
      {trendings.length == 0 && (
        <div className={styles["no-article-message"]}>
          {landingMessage.NO_TRENDING[lang]}
        </div>
      )}
    </div>
  );
};
