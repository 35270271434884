import { Row, Col, Input, Checkbox, Button, notification, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showMemberHeader,
  updateHaveNewVideo,
} from "../../features/member/memberSlice";
import { showSider, changeActiveItem } from "../../features/sider/siderSlice";
import { useSelector } from "react-redux";
import {
  siderMessage,
  MemberVideoMessage,
  landingMessage,
} from "../../message/message";
import { MemberHomeListingPage } from "../../component/custom/MemberHome/MemberHomeListingPage";
import styles from "./member.module.scss";
import { ClientAPIClient } from "../../service/web-api-client.ts";
import {
  dateToStrConverter,
  convertDateTimeForDisplay,
  convertDateForAPI,
} from "../../auth/utils";

const MemberHome = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const dispatch = useDispatch();
  const [isLoading, set_isLoading] = useState(false);
  const [videoList, set_videoList] = useState([]);
  useEffect(() => {
    set_isLoading(true);
    dispatch(showSider());
    dispatch(changeActiveItem("HOME")); // refer to src/component/common/CustomSider.js
    dispatch(showMemberHeader());
    (async () => {
      try {
        const ClientAPI_Client = new ClientAPIClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const result_ClientAPI = await ClientAPI_Client.getVideoAndVideoCat();
        //console.log("result_ClientAPI", result_ClientAPI);
        dispatch(
          updateHaveNewVideo(
            result_ClientAPI.filter((e) => e.progress == "New").length > 0
          )
        );
        set_videoList(refineAPIData(result_ClientAPI));
        set_isLoading(false);
      } catch (e) {
        notification.error({
          message: landingMessage.ERROR[lang],
        });
        console.log(e);
      }
    })();
  }, []);
  const refineAPIData = (APIData) => {
    const result = APIData.map((item) => {
      return {
        ...item,
        VimeoId: item.url,
        creDt: `${convertDateTimeForDisplay(convertDateForAPI(item.creDt))}`,
      };
    });
    return result;
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <div className={styles["member-home-background"]}>
          <MemberHomeListingPage
            PageCode={"NewVideo"}
            Title={MemberVideoMessage.AddNewMovie_List[lang]}
            Lang={lang}
            videoList={videoList?.filter((e) => e.progress == "New")}
          />
          <MemberHomeListingPage
            PageCode={"Continue"}
            Title={MemberVideoMessage.KeepWatch[lang]}
            Lang={lang}
            videoList={videoList?.filter((e) => e.progress == "Continue")}
          />
          <MemberHomeListingPage
            PageCode={"CompletedVideo"}
            Title={MemberVideoMessage.CompletedMovie_List[lang]}
            Lang={lang}
            IsCompleted={true}
            videoList={videoList?.filter((e) => e.progress == "Complete")}
          />
        </div>
      </Spin>
    </>
  );
};

export default MemberHome;
