import React, { createRef, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "./App.css";
import "./styles/scss/custom.scss";
import "./styles/scss/override-antd.scss";
import "./styles/scss/override-kendo.scss";
import { useSelector } from "react-redux";

import { Layout, notification, Drawer, Button } from "antd";
import CustomHeader from "./component/common/CustomHeader";
import CustomHeaderMobile from "./component/common/CustomHeaderMobile";
import CustomFooter from "./component/common/CustomFooter";
import CustomSider from "./component/common/CustomSider";
import SubHeader from "./component/common/SubHeader";

import Landing from "./pages/Landing";
import Login from "./pages/Login";
import MemberHome from "./pages/member/MemberHome";
import MemberHeader from "./component/common/MemberHeader";
import MemberProfile from "./pages/member/MemberProfile";
import MemberVideo from "./pages/member/MemberVideo";
import MemberViewVideo from "./pages/member/MemberViewVideo";
import MemberViewVideoCat from "./pages/member/MemberViewVideoCat";
import TrendingListing from "./pages/trending/TrendingListing";
import FocusListing from "./pages/focus/FocusListing";
import FocusDetail from "./pages/focus/FocusDetail";
import TrendingDetail from "./pages/trending/TrendingDetail";
import { getUserData, getMemberTelPhone, logout } from "./utils/Utils";
import { AuthenticateClient } from "./service/web-api-client.ts";
import { useCookies } from "react-cookie";
import { changeLanguage } from "./features/language/languageSlice";
import { ClientAPIClient } from "./service/web-api-client.ts";
import { RightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux/es/exports";
import { hideSider } from "./features/sider/siderSlice";
import {
  hideMemberHeader,
  showMemberHeader,
  updateHaveNewVideo,
} from "./features/member/memberSlice";

import { GeneralMessage } from "./message/message";

const { Header, Footer, Content, Sider } = Layout;

const ProtectedRoute = ({ user, lang, redirectPath = "/" }) => {
  const dispatch = useDispatch();
  const [passedState, set_passState] = useState(false);
  const nav = useNavigate();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      if (!user) {
        dispatch(hideSider());
        dispatch(hideMemberHeader());
        notification.warning({
          message: GeneralMessage.LOGIN_FIRST[lang],
        });
        return nav("/Login");
      }
      if (user) {
        try {
          const authenticateClient = new AuthenticateClient(
            process.env.REACT_APP_API_BASE_URL
          );
          const result = await authenticateClient.refreshToken({
            accessToken: localStorage.getItem("accessToken"),
            refreshToken: localStorage.getItem("refreshToken"),
            userName: getMemberTelPhone(),
          });
          // console.log(result);
          localStorage.setItem("accessToken", result.accessToken);
          localStorage.setItem("refreshToken", result.refreshToken);
          set_passState(true);
          dispatch(showMemberHeader());
          const ClientAPI_Client = new ClientAPIClient(
            process.env.REACT_APP_API_BASE_URL
          );
          const result_ClientAPI = await ClientAPI_Client.getVideoAndVideoCat();
          //console.log("result_ClientAPI", result_ClientAPI);
          dispatch(
            updateHaveNewVideo(
              result_ClientAPI.filter((e) => e.progress == "New").length > 0
            )
          );
          // console.log("refreshToken success");
        } catch (e) {
          console.log(e);
          await logout();
          dispatch(hideSider());
          dispatch(hideMemberHeader());
          notification.warning({
            message: GeneralMessage.LOGIN_FIRST[lang],
          });
          return nav("/Login");
        }
      }
    })();
  }, []);

  return <>{passedState && <Outlet />}</>;
};

function App() {
  const siderComponent = createRef();
  const sider = useSelector((state) => state.sider);
  const member = useSelector((state) => state.member);
  const [cookie, set_cookie] = useCookies(["rememberLang"]);
  const lang = useSelector((state) => state.lang.value);
  const location = useLocation();
  const user = getUserData();
  const [isLarge, set_isLarge] = useState(window.innerWidth > 768);
  const [drawerVisible, set_drawerVisible] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    set_cookie("rememberLang", lang, { path: "/" });
    // console.log("cookie", cookie.rememberLang);
    // console.log("store", lang);
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });
    window.addEventListener("resize", function (e) {
      set_isLarge(window.innerWidth > 768);
    });
  }, [location]);

  const onShow = () => {
    set_drawerVisible(true);
  };

  const onClose = () => {
    set_drawerVisible(false);
  };

  return (
    <div className="App">
      <Layout>
        <Header>
          {isLarge && <CustomHeader />}
          {!isLarge && <CustomHeaderMobile />}
        </Header>

        <SubHeader />
        {member.headerVisible && <MemberHeader />}

        <Layout>
          {sider.visible && isLarge && (
            <Sider
              ref={siderComponent}
              breakpoint="md"
              collapsedWidth="0"
              theme={"light"}
              className="test_1"
              onClick={(e) => {
                // if (e.target.classList.contains("sider-item")) {
                const trigger = document.querySelector(
                  ".ant-layout-sider-zero-width-trigger"
                );
                if (trigger) {
                  trigger.click();
                }
                // }
              }}
            >
              <CustomSider />
            </Sider>
          )}
          {sider.visible && !isLarge && (
            <>
              <div className="fix-menu-button">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<RightOutlined />}
                  onClick={() => {
                    onShow();
                  }}
                ></Button>
              </div>
              <Drawer
                title=""
                placement={"left"}
                closable={false}
                onClose={onClose}
                onClick={onClose}
                visible={drawerVisible}
                key={"right"}
                className="align-center"
              >
                <CustomSider />
              </Drawer>
            </>
          )}

          <Routes>
            <Route
              exact
              path="/"
              element={
                <Content>
                  <Landing />
                </Content>
              }
            ></Route>
            <Route
              exact
              path="/Login"
              element={
                <Content>
                  <Login />
                </Content>
              }
            ></Route>
            <Route
              exact
              path="/Focus"
              element={
                <Content>
                  <FocusListing />
                </Content>
              }
            ></Route>
            <Route
              exact
              path="/Focus/:web_content_id"
              element={
                <Content>
                  <FocusDetail />
                </Content>
              }
            ></Route>
            <Route
              exact
              path="/Trending"
              element={
                <Content>
                  <TrendingListing />
                </Content>
              }
            ></Route>
            <Route
              exact
              path="/Trending/:web_content_id"
              element={
                <Content>
                  <TrendingDetail />
                </Content>
              }
            ></Route>
            {/* BELOW ARE PAGES THAT ONLY ALLOW TO ACCESS AFTER LOGIN */}
            <Route element={<ProtectedRoute user={user} lang={lang} />}>
              <Route
                exact
                path="/Home"
                element={
                  <Content>
                    <MemberHome />
                  </Content>
                }
              ></Route>
              <Route
                exact
                path="/Profile"
                element={
                  <Content>
                    <MemberProfile />
                  </Content>
                }
              ></Route>
              <Route
                exact
                path="/Video"
                element={
                  <Content>
                    <MemberVideo />
                  </Content>
                }
              ></Route>
              <Route
                exact
                path="/Video/:PageCode"
                element={
                  <Content>
                    <MemberVideo />
                  </Content>
                }
              ></Route>
              <Route
                exact
                path="/ViewVideoCat/:video_cat_id"
                element={
                  <Content>
                    <MemberViewVideoCat />
                  </Content>
                }
              ></Route>
              <Route
                exact
                path="/ViewVideo/:video_id"
                element={
                  <Content>
                    <MemberViewVideo />
                  </Content>
                }
              ></Route>
              <Route
                exact
                path="/ViewVideo/:video_cat_id/:video_id"
                element={
                  <Content>
                    <MemberViewVideo />
                  </Content>
                }
              ></Route>
            </Route>
            <Route path="*" element={<Content>404</Content>}></Route>
          </Routes>
        </Layout>
        <Footer>
          <CustomFooter />
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
