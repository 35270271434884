import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import WebContentListing from "../../component/custom/WebContent/WebContentListing";
import { landingMessage } from "../../message/message";
import styles from "./trending.module.scss";
import { ClientAPIClient } from "../../service/web-api-client.ts";
import { notification, Spin } from "antd";

const TrendingListing = (props) => {
  const [isLoading, set_isLoading] = useState(false);
  const [dataState, set_dataState] = useState(null);
  const lang = useSelector((state) => state.lang.value);
  useEffect(() => {
    set_isLoading(true);
    (async () => {
      try {
        const client = new ClientAPIClient(process.env.REACT_APP_API_BASE_URL);
        const result = await client.getWebContentList();
        set_dataState(
          result.sort(
            (a, b) => Number(new Date(b.creDt)) - Number(new Date(a.creDt))
          )
        );
        set_isLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <div>
      <Spin spinning={isLoading}>
        <div className={styles["trending-title"]}>
          {landingMessage.TRENDING_TITLE[lang]}
        </div>
        {dataState && (
          <WebContentListing
            webContents={dataState.filter((obj) => obj.type === "Trending")}
            type={"Trending"}
          />
        )}
      </Spin>
    </div>
  );
};

export default TrendingListing;
