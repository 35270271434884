import { footerMessage } from "../../message/message.js";
import { Row, Col, Divider } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import NAD from "../../assets/img/NAD.jpg";
import JCFC from "../../assets/img/JCFC.jpg";
import DSC from "../../assets/img/DSC-Logo.jpg";
const CustomFooter = (props) => {
  const lang = useSelector((state) => state.lang.value);

  return (
    <div className="custom-footer custom-footer-content">
      <div className="footer-title">{footerMessage.CONTACT_US[lang]}</div>
      <Row>
        <Col lg={8} sm={24} xs={24}>
          <div style={{ paddingBottom: "2vh" }}>
            <div className="title">{footerMessage.CENTER1TITLE[lang]}</div>
            <div className="content">
              <img
                style={{ width: window.innerWidth < 992 ? "50vw" : "10vw" }}
                src={NAD}
              ></img>
            </div>

            <div className="content"> {footerMessage.CENTER1TEL[lang]}</div>
            <div className="content"> {footerMessage.CENTER1FAX[lang]}</div>
            <div className="content">{footerMessage.CENTER1EMAIL[lang]}</div>
            {/* <div className="content">{footerMessage.CENTER1ADDRESS[lang]}</div> */}
            {/* <div className="content">
              {footerMessage.CENTER1OPENING_HOUR[lang]}
            </div> */}
          </div>
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <div style={{ paddingBottom: "2vh" }}>
            <div className="title">{footerMessage.CENTER2TITLE[lang]}</div>
            <div className="content">
              <img
                style={{ width: window.innerWidth < 992 ? "50vw" : "10vw" }}
                src={JCFC}
              ></img>
            </div>
            <div className="content"> {footerMessage.CENTER2TEL[lang]}</div>
            <div className="content"> {footerMessage.CENTER2FAX[lang]}</div>
            <div className="content">{footerMessage.CENTER2EMAIL[lang]}</div>
            {/* <div className="content">{footerMessage.CENTER2ADDRESS[lang]}</div> */}
            {/* <div className="content">
              {footerMessage.CENTER2OPENING_HOUR[lang]}
            </div> */}
          </div>
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <div style={{ paddingBottom: "2vh" }}>
            <div className="title">{footerMessage.CENTER3TITLE[lang]}</div>
            <div className="content">
              <img
                style={{ width: window.innerWidth < 992 ? "50vw" : "10vw" }}
                src={DSC}
              ></img>
            </div>
            <div className="content"> {footerMessage.CENTER3TEL[lang]}</div>
            <div className="content"> {footerMessage.CENTER3FAX[lang]}</div>
            <div className="content">{footerMessage.CENTER3EMAIL[lang]}</div>
            {/* <div className="content">{footerMessage.CENTER3ADDRESS[lang]}</div> */}
            {/* <div className="content">
              {footerMessage.CENTER3OPENING_HOUR[lang]}
            </div> */}
          </div>
        </Col>
        {/* <Col lg={12} sm={24} xs={24}>
          <div className="title">{footerMessage.SITE_MAP[lang]}</div>
          <Row>
            <Col className="content" span="12">
              <Link to="/">{footerMessage.HOME[lang]}</Link>
            </Col>
            <Col className="content" span="12">
              <Link to="/Login">{footerMessage.LOGIN[lang]}</Link>
            </Col>
            <Col className="content" span="12">
              <Link to="/Trending">{footerMessage.TRENDING[lang]}</Link>
            </Col>
            <Col className="content" span="12">
              <Link to="/Focus">{footerMessage.FOCUS[lang]}</Link>
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Divider orientation="left" className={"footer-divider"}></Divider>
      {footerMessage.DEVELOPBY[lang]}
    </div>
  );
};

export default CustomFooter;
