export const headerMessage = {
  ABOUT_US: {
    en: "About Us",
    zh: "關於我們",
  },
  SHARING: {
    en: "Publication Sharing",
    zh: "刊物分享",
  },
  JOIN_US: {
    en: "Join Us",
    zh: "加入我們",
  },
  CONTACT_US: {
    en: "Contact Us",
    zh: "聯絡我們",
  },
  STAFF_LOGIN: {
    en: "Staff Login",
    zh: "職員登入",
  },
  DOWNLOAD_REGISTER_FORM: {
    en: "Download Application Form",
    zh: "申請表下載",
  },
};

export const footerMessage = {
  CONTACT_US: {
    en: "Contact Us",
    zh: "聯絡我們",
  },
  SITE_MAP: {
    en: "Site Map",
    zh: "網頁指南",
  },
  CENTER1TITLE: {
    en: "Jockey Club Support Programme for Persons with Newly Acquired Disabilities",
    zh: "賽馬會「動 • 絡」新傷殘人士支援計劃",
  },
  CENTER1TEL: {
    en: "Tel No : (852) 3705 5328",
    zh: "電話號碼：(852) 3705 5328",
  },
  CENTER1FAX: {
    en: "Fax No : (852) 2426 5188",
    zh: "傳真號碼：(852) 2426 5188",
  },
  CENTER1EMAIL: {
    en: "Email : nad@hkphab.org.hk",
    zh: "電郵地址：nad@hkphab.org.hk",
  },
  CENTER1ADDRESS: {
    en: "Address : Room 1402, 21 Pak Fuk Road, North Point, Hong Kong",
    zh: "地址：香港北角百福道21號1402室",
  },
  CENTER1OPENING_HOUR: {
    en: "Opening Hours : 9:00am-12:30pm 1:30pm-5:30pm",
    zh: "辦公時間：9:00am-12:30pm 1:30pm-5:30pm",
  },

  CENTER2TITLE: {
    en: "Jockey Club PHAB Fitness Centre",
    zh: "賽馬會傷健體適能中心",
  },
  CENTER2TEL: {
    en: "Tel No : (852) 2651 1468",
    zh: "電話號碼：(852) 2651 1468",
  },
  CENTER2FAX: {
    en: "Fax No : (852) 3460 5876",
    zh: "傳真號碼：(852) 3460 5876",
  },
  CENTER2EMAIL: {
    en: "Email : jcfc@hkphab.org.hk",
    zh: "電郵地址：jcfc@hkphab.org.hk",
  },
  CENTER2ADDRESS: {
    en: "Address : Room 1402, 21 Pak Fuk Road, North Point, Hong Kong",
    zh: "地址：香港北角百福道21號1402室",
  },
  CENTER2OPENING_HOUR: {
    en: "Opening Hours : 9:00am-12:30pm 1:30pm-5:30pm",
    zh: "辦公時間：9:00am-12:30pm 1:30pm-5:30pm",
  },
  CENTER3TITLE: {
    en: "i-point District Support Center (Southern)",
    zh: "「融 • 點」地區支援中心 ( 南區 )",
  },
  CENTER3TEL: {
    en: "Tel No : (852) 3905 0465",
    zh: "電話號碼：(852) 3905 0465",
  },
  CENTER3FAX: {
    en: "Fax No : (852) 3464 0684",
    zh: "傳真號碼：(852) 3464 0684",
  },
  CENTER3EMAIL: {
    en: "Email : i-pointdsc@hkphab.org.hk",
    zh: "電郵地址：i-pointdsc@hkphab.org.hk",
  },
  CENTER3ADDRESS: {
    en: "Address : xxx",
    zh: "地址：xxx",
  },
  CENTER3OPENING_HOUR: {
    en: "Opening Hours : 9:00am-12:30pm 1:30pm-5:30pm",
    zh: "辦公時間：9:00am-12:30pm 1:30pm-5:30pm",
  },
  HOME: {
    en: "Home",
    zh: "首頁",
  },
  TRENDING: {
    en: "Trending",
    zh: "中心動態",
  },
  FOCUS: {
    en: "Focus",
    zh: "焦點文章",
  },
  LOGIN: {
    en: "Member Login",
    zh: "會員登入",
  },
  DEVELOPBY: {
    en: "Developed By Pro-Tech Software (Asia) Limited",
    zh: "此網站由 Pro-Tech Software (Asia) Limited 開發",
  },
};

export const subHeaderMessage = {
  LOGIN: {
    en: "Login",
    zh: "登入",
  },
  LOGOUT: {
    en: "Logout",
    zh: "登出",
  },
};

export const memberHeaderMessage = {
  MEMBER_NUM_LABEL: {
    en: "Member No.: ",
    zh: "會員編號: ",
  },
  HAVE_NEW_VIDEO: {
    en: "Click me to check out the new Videos!",
    zh: "點我以查看我的影片/片單",
  },
};

export const siderMessage = {
  HOME: {
    en: "Home",
    zh: "首頁",
  },
  MY_PROFILE: {
    en: "My Profile",
    zh: "我的檔案",
  },
  MY_VIDEO: {
    en: "My Video / Video List",
    zh: "我的影片 / 片單",
  },
};

export const landingMessage = {
  NO_FOCUS: {
    en: "No Focus Articles",
    zh: "沒有焦點文章",
  },
  NO_TRENDING: {
    en: "No Trending Articles",
    zh: "沒有中心動態",
  },
  FORGET_PASSWORD_MODAL_TITLE: {
    en: "Forget Password",
    zh: "忘記密碼",
  },
  FORGET_PASSWORD_MODAL_INPUT: {
    en: "Login Account (Mobile Phone)",
    zh: "登入帳戶 (電話號碼)",
  },
  FORGET_PASSWORD_MODAL_BUTTON: {
    en: "Send Password",
    zh: "發送密碼",
  },
  Member_Login: {
    en: "Member Login",
    zh: "會員登入",
  },
  PROJECT_NAME: {
    en: "Jockey Club Support Programme for Persons with Newly Acquired Disabilities",
    zh: "賽馬會「動 • 絡」新傷殘人士支援計劃",
  },
  TITLE: {
    en: "Digital Learning Hub",
    zh: "數碼互動學習平台",
  },
  DESCRIPTION1: {
    en: `Digital Learning Hub is a new generation of online learning platform that aims to foster self-management in an easy-to-access threshold. `,
    zh: `數碼互動學習平台是新一代網上學習模式，以革新及簡易方法提昇參加者的自主學習能力。`,
  },
  DESCRIPTION2: {
    en: `In response to the era of electronic data and the changing rehabilitation needs, our platform is committed to developing personalized e-learning videos and articles including home rehabilitation exercises, caring skills, nutrition programs, drugs management, and psychosocial programs, to arouse participants’ interest in learning, keep the training in anywhere and optimize the training outcomes.`,
    zh: `為迎接電子數據時代及回應個人的復康需求，這平台透過多元化的學習短片及文章，如家居復康訓練、照顧技巧、飲食及營養、藥物管理、身心靈關顧等，讓每位參加者能隨時隨地延續個人的復康訓練及提昇整體的訓練果效。`,
  },
  LOGIN_PLACEHOLDER: {
    en: "Login Account (Mobile Phone)",
    zh: "登入帳戶 (電話號碼)",
  },
  PASSWORD_PLACEHOLDER: {
    en: "Password",
    zh: "密碼",
  },
  CAPTCHA_PLACEHOLDER: {
    en: "Type In Verification Code",
    zh: "輸入驗證碼",
  },
  REMEMBER_ME: {
    en: "Remember Me",
    zh: "記住我",
  },
  LOGIN: {
    en: "Login",
    zh: "登入",
  },
  CANCEL: {
    en: "Cancel",
    zh: "取消",
  },
  WELCOME_BACK: {
    en: "Welcome Back!",
    zh: "歡迎回來！",
  },
  MEMBER_HOME_NAV: {
    en: "Home",
    zh: "首頁",
  },
  MEMBER_INFO_NAV: {
    en: "My Info",
    zh: "我的資料",
  },
  MEMBER_VIDEO_NAV: {
    en: "My Video/List",
    zh: "我的影片/片單",
  },
  LEARN_MORE: {
    en: "Learn More...",
    zh: "了解更多...",
  },
  TRENDING_TITLE: {
    en: "Trending",
    zh: "中心動態",
  },
  FOCUS_TITLE: {
    en: "Focus",
    zh: "焦點文章",
  },
  CARD_DESCRIPTION: {
    en: "Click To Learn More...",
    zh: "按此了解詳情...",
  },
  LOGIN_SUCCESS: {
    en: "Welcome Back, ",
    zh: "歡迎回來，",
  },
  MESSAGE_SENT: {
    en: "The new password is sent to your Mobile Phone",
    zh: "新密碼已發送到你的電話號碼",
  },
  EMPTY_USERNAME: {
    en: "Please Fill in Login Account (Mobile Phone)",
    zh: "請輸入 登入帳戶 (電話號碼)",
  },
  EMPTY_PASSWORD: {
    en: "Please Fill in Password",
    zh: "請輸入 密碼",
  },
  WRONG_CAPTCHA: {
    en: "Wrong Verification Code, Please Try Again",
    zh: "驗證碼不正確。請重新輸入。",
  },
  ERROR: {
    en: "Something Wrong",
    zh: "發生錯誤",
  },
  Only_Selected_Members: {
    en: "Only selected members.",
    zh: "只限已選擇之會員",
  },
  NETWORK_ERROR: {
    en: "Fail to connect to Internet, please check your network connection.",
    zh: "連接網絡時發生錯誤，請檢查網絡連線是否正常。",
  },
  WRONG_LOGIN_INFO: {
    en: "Wrong Login Account or Password.",
    zh: "帳戶或密碼不正確。",
  },
  WRONG_NUMBER: {
    en: "This Phone Number does not exist",
    zh: "電話號碼不存在",
  },
  Forget_Password: {
    en: "Forget password",
    zh: "忘記密碼",
  },
  RELOAD_CAPTCHA: {
    en: "Reload Captcha",
    zh: "重設驗證碼",
  },
};
export const memberProfileMessage = {
  MemberProfile_title: {
    en: "My Information",
    zh: "我的檔案",
  },
  MemberProfile_nameCn: {
    en: "Chinese Name",
    zh: "中文姓名",
  },
  MemberProfile_nameEn: {
    en: "English Name",
    zh: "英文姓名",
  },
  MemberProfile_Name: {
    en: "Name",
    zh: "名稱",
  },
  MemberProfile_Surname: {
    en: "Surname",
    zh: "姓氏",
  },
  MemberProfile_TelePhone: {
    en: "Phone No.",
    zh: "電話",
  },
  MemberProfile_Sex: {
    en: "Sex",
    zh: "性別",
  },
  MemberProfile_Age: {
    en: "Age",
    zh: "年齡",
  },
  MemberProfile_Address: {
    en: "Address",
    zh: "住址",
  },
  MemberProfile_IsHealth: {
    en: "Special physical condition",
    zh: "特殊身體狀況",
  },
};
export const memberHomeListingPage = {
  Watch: {
    en: "Watch",
    zh: "觀看",
  },
  ReWatch: {
    en: "Re-watch",
    zh: "重新觀看",
  },
  Questionnaire: {
    en: "Survey",
    zh: "問卷調查",
  },
  QuestionnaireDone: {
    en: "Survey Done",
    zh: "已完成問卷",
  },
  LeaveMessage: {
    en: "Message",
    zh: "留言",
  },
  BriefIntroduction: {
    en: "Description",
    zh: "簡介",
  },
  ShowAll: {
    en: "Show all",
    zh: "顯示全部",
  },
  NO_VIDEO: {
    en: "No Video",
    zh: "沒有影片",
  },
};
export const MemberVideoMessage = {
  AddNewMovie: {
    en: "New Video",
    zh: "新加入影片",
  },
  KeepWatch: {
    en: "Keep Watching",
    zh: "繼續觀看",
  },
  CompletedMovie: {
    en: "Completed Video",
    zh: "已完成影片",
  },
  CompletedQuestionnaire: {
    en: "Done Survey",
    zh: "已完成問卷",
  },

  AddNewMovie_List: {
    en: "New Video/List",
    zh: "新加入影片/片單",
  },
  CompletedMovie_List: {
    en: "Completed Video/list",
    zh: "已完成的影片/片單",
  },
};

export const GeneralMessage = {
  LOGIN_FIRST: {
    en: "Please Login First",
    zh: "請先登入",
  },
  CANCEL: {
    en: "Cancel",
    zh: "取消",
  },
  OKAY: {
    en: "Submit",
    zh: "確認",
  },
  SUCCESSFULLY_COMMENT: {
    en: "Message Submitted",
    zh: "留言已送出",
  },
  FAIL_COMMENT: {
    en: "Failed. Please Try Again.",
    zh: "發生錯誤。留言未能送出。",
  },
  INACTIVE_MEMBER_LOGIN: {
    en: "Invalid Member",
    zh: "會員無效",
  },
};

export const WebContentMessage = {
  BACK: {
    en: "Read Other",
    zh: "閱讀其他",
  },
};
