import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "../features/language/languageSlice";
import siderReducer from "../features/sider/siderSlice";
import memberSlice from "../features/member/memberSlice";

const store = configureStore({
  reducer: {
    lang: languageReducer,
    sider: siderReducer,
    member: memberSlice,
  },
});

export default store;
