import useJwt from "../assets/auth/jwt/useJwt";

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem("userData") &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return { name: "access-control" };
  return { name: "auth-login" };
};
function addHours(numOfHours, date) {
  if (date) {
    const secondToAdd = numOfHours * 3600000;
    date.setTime(date.getTime() + secondToAdd);

    return date;
  }
  return null;
}
export const simpleTimeToStrConverter = (dateObject) => {
  if (dateObject) {
    const hh = dateObject.getHours(); // getMonth() is zero-based
    const mm = dateObject.getMinutes();
    const ss = dateObject.getSeconds();

    const timeResult = [
      (hh > 9 ? "" : "0") + hh,
      (mm > 9 ? "" : "0") + mm,
      (ss > 9 ? "" : "0") + ss,
    ].join(":");
    return timeResult;
  } else {
    return "";
  }
};

export const convertDateForAPI = (dateObject) => {
  if (dateObject && typeof dateObject == "object") {
    return addHours(8, dateObject);
  } else if (typeof dateObject == "string") {
    return new Date(`${dateObject.replace("Z", "")}Z`);
  }
  return dateObject;
};
export const dateToStrConverter = (dateObject) => {
  const mm = dateObject.getMonth() + 1; // getMonth() is zero-based
  const dd = dateObject.getDate();

  return [
    dateObject.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("-");
};
export const convertDateTimeForDisplay = (dateObject) => {
  if (dateObject && typeof dateObject == "object") {
    return `${dateToStrConverter(dateObject)} ${simpleTimeToStrConverter(
      dateObject
    )}`;
  } else if (
    dateObject &&
    typeof dateObject == "string" &&
    dateObject.includes("T")
  ) {
    const newdateObject = new Date(
      dateObject.replace("T", " ").replace("Z", "").replace(/-/g, "/")
    );
    return `${dateToStrConverter(newdateObject)} ${simpleTimeToStrConverter(
      newdateObject
    )}`;
  }
  return dateObject;
};
