import { Row, Col } from "antd/lib/grid";
import { getUserData } from "../../utils/Utils";
import { User, Bell, BellOff } from "react-feather";
import { memberHeaderMessage } from "../../message/message";
import { useSelector } from "react-redux";
import { Button, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";

const MemberHeader = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const member = useSelector((state) => state.member);
  const nav = useNavigate();

  return (
    <Row
      className="member-header member-header-row"
      justify="space-between"
      align="middle"
    >
      <Col flex={"auto"}>
        <Row align="middle">
          <Col>
            <div className="member-header-avatar">
              <User size={56} />
            </div>
          </Col>
          <Col>
            <div className="member-header-detail">
              <div>
                {lang == "en"
                  ? `${getUserData().firstNameEn} ${getUserData().lastNameEn}`
                  : getUserData()?.nameCn}
              </div>
              <div>
                {`${memberHeaderMessage.MEMBER_NUM_LABEL[lang]} ${
                  getUserData()?.memberNum
                }`}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col style={{ textAlign: "end" }} flex={"auto"}>
        {member.info.haveNewVideo && (
          <Tooltip
            placement="left"
            title={memberHeaderMessage.HAVE_NEW_VIDEO[lang]}
          >
            <Link to="/Video/NewVideo">
              <Bell />
            </Link>
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

export default MemberHeader;
