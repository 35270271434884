import Carousel from "react-multi-carousel";
import { Button, Card } from "antd";
import styles from "./landing.module.scss";
import { landingMessage } from "../../../message/message";
import { useSelector } from "react-redux/es/exports";
import { Link, useNavigate } from "react-router-dom";
import default_img from "../../../assets/img/nad-logo.png";
export const Focus = (props) => {
  const { focuses } = props;
  const lang = useSelector((state) => state.lang.value);

  const nav = useNavigate();
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };
  console.log(focuses.length);
  return (
    <div className={styles["focus-session"]}>
      <div className={styles["more-button"]}>
        <Link to="/Focus">
          <Button type="danger" shape="round">
            {landingMessage.LEARN_MORE[lang]}
          </Button>
        </Link>
      </div>
      <div className={styles["focus-session-title"]}>
        {landingMessage.FOCUS_TITLE[lang]}
      </div>

      {focuses.length > 0 && (
        <div
          style={{
            margin:
              focuses.length == 1 && window.innerWidth > 500 ? "auto" : "0px",
            width:
              focuses.length == 1 && window.innerWidth > 500 ? "50%" : "100%",
          }}
        >
          <div style={{ minWidth: "300px" }}>
            <Carousel
              swipeable={true}
              responsive={{
                superLargeDesktop: {
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 3000 },
                  items: focuses.length > 3 ? 3 : focuses.length,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: focuses.length > 3 ? 3 : focuses.length,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: focuses.length > 2 ? 2 : focuses.length,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: focuses.length > 1 ? 1 : focuses.length,
                },
              }}
            >
              {focuses.map((item) => {
                return (
                  <div
                    className={styles["carousel-container"]}
                    // style={{ textAlign: "center" }}
                  >
                    <Card
                      hoverable
                      className={styles["carousel-card"]}
                      // style={{ maxWidth: "50%" }}
                      cover={
                        <img
                          alt=""
                          src={item.url ? item.url : default_img}
                          width="auto"
                          height="auto"
                        />
                      }
                      onClick={() => {
                        nav(`/Focus/${item.sysId}`);
                      }}
                    >
                      <h2>{lang == "en" ? item.titleEn : item.title}</h2>
                      <p>{landingMessage.CARD_DESCRIPTION[lang]}</p>
                    </Card>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
      {focuses.length == 0 && (
        <div className={styles["no-article-message"]}>
          {landingMessage.NO_FOCUS[lang]}
        </div>
      )}
    </div>
  );
};
