import {
  Row,
  Col,
  Input,
  Checkbox,
  Button,
  notification,
  Modal,
  Space,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux/es/exports";
import styles from ".././styles/scss/login.module.scss";
import { landingMessage, GeneralMessage } from "../message/message";
import { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import { useNavigate, Link } from "react-router-dom";
import {
  AuthenticateClient,
  CurrentLoginUserClient,
} from ".././service/web-api-client.ts";
import { useCookies } from "react-cookie";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { logout } from "../utils/Utils";
import { ClientAPIClient } from "../service/web-api-client.ts";

const Login = (props) => {
  const [loginLoading, set_loginLoading] = useState(false);
  const [cookie, set_cookie] = useCookies(["rememberUserName"]);
  const [modalVisible, set_modalVisible] = useState(false);
  const [modalState, set_modalState] = useState({});
  let navigate = useNavigate();
  const lang = useSelector((state) => state.lang.value); // use store state

  const [dataState, set_dataState] = useState({
    userName: cookie.rememberUserName || "",
    password: cookie.rememberPassword || "",
    captcha: "",
    rememberMe: true,
  });
  const vimeoRef = useRef(null);

  const toggleModal = () => {
    set_modalVisible(!modalVisible);
  };
  const openModal = () => {
    toggleModal();
  };
  const modalChangeHandler = (field, value) => {
    set_modalState({ ...modalState, [field]: value });
  };

  const changeHandler = (field, value) => {
    set_dataState({ ...dataState, [field]: value });
  };

  const loginHandler = () => {
    set_loginLoading(true);
    if (dataState.userName.length == 0) {
      set_loginLoading(false);

      return notification.warning({
        message: landingMessage.EMPTY_USERNAME[lang],
      });
    }
    if (dataState.password.length == 0) {
      set_loginLoading(false);

      return notification.warning({
        message: landingMessage.EMPTY_PASSWORD[lang],
      });
    }
    if (!validateCaptcha(dataState.captcha)) {
      set_loginLoading(false);

      return notification.warning({
        message: landingMessage.WRONG_CAPTCHA[lang],
      });
    }
    (async () => {
      try {
        const client = new AuthenticateClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const result = await client.memberLogin(dataState);
        if (!result) {
          set_loginLoading(false);
          return notification.error({
            message: landingMessage.WRONG_LOGIN_INFO[lang],
          });
        }
        if (result.message) {
          set_loginLoading(false);
          return notification.error({
            message: GeneralMessage.INACTIVE_MEMBER_LOGIN[lang],
          });
        }
        localStorage.setItem("refreshToken", result.refreshToken);
        localStorage.setItem("accessToken", result.token);
        const currentLoginUserClient = new CurrentLoginUserClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const userData = await currentLoginUserClient.getCurrentMember();
        localStorage.setItem("userData", JSON.stringify(userData));
        if (dataState.rememberMe) {
          set_cookie("rememberUserName", dataState.userName, { path: "/" });
          set_cookie("rememberPassword", dataState.password, { path: "/" });
        } else {
          set_cookie("rememberUserName", "", { path: "/" });
          set_cookie("rememberPassword", "", { path: "/" });
        }
        navigate("/Home");
      } catch (e) {
        console.log(e);
        set_loginLoading(false);
        logout();
        notification.error({
          message: landingMessage.ERROR[lang],
        });
      }
    })();
    //
  };
  const cancelHandler = () => {
    navigate("/");
    // set_dataState({
    //   ...dataState,
    //   userName: "",
    //   password: "",
    // });
  };

  const sendSMS = () => {
    const client = new ClientAPIClient(process.env.REACT_APP_API_BASE_URL);
    (async () => {
      try {
        const result = await client.resetPassword(modalState.telPhone);
        if (result.message == "Fail") {
          notification.error({ message: landingMessage.WRONG_NUMBER[lang] });
        } else {
          notification.success({ message: landingMessage.MESSAGE_SENT[lang] });
        }
      } catch (e) {
        console.log("something wrong");
        console.log(e);
        notification.error({
          message: landingMessage.ERROR[lang],
        });
      }
    })();
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
    document.getElementById("reload_href").innerHTML =
      landingMessage.RELOAD_CAPTCHA[lang];
  }, [lang]);

  return (
    <>
      <div className={styles["online-support-session"]}>
        <div className={styles["middle-of-the-page-white"]}>
          <Row
            style={{ paddingBottom: "10px", margin: "auto", padding: "20px" }}
          >
            <Col>
              <div className={styles["label-title"]}>
                {landingMessage.Member_Login[lang]}
              </div>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="my-2 h4">
                {landingMessage.LOGIN_PLACEHOLDER[lang]}
              </div>
              <Input
                size="large"
                placeholder={landingMessage.LOGIN_PLACEHOLDER[lang]}
                value={dataState.userName}
                onChange={(e) => {
                  changeHandler("userName", e.target.value);
                }}
              />
              <div className="my-2 h4">
                {landingMessage.PASSWORD_PLACEHOLDER[lang]}
              </div>
              <Input.Password
                size="large"
                placeholder={landingMessage.PASSWORD_PLACEHOLDER[lang]}
                value={dataState.password}
                onChange={(e) => {
                  changeHandler("password", e.target.value);
                }}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Checkbox
                defaultChecked={dataState.rememberMe}
                onChange={(e) => {
                  changeHandler("rememberMe", e.value);
                }}
              >
                {landingMessage.REMEMBER_ME[lang]}
              </Checkbox>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="my-2 h4" style={{ float: "right" }}>
                <Link
                  onClick={() => openModal()}
                  to="#"
                  style={{ color: "black" }}
                >
                  {landingMessage.Forget_Password[lang]}
                </Link>
              </div>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className={styles["online-support-remember-me-checkbox"]}>
                <Row justify="space-between">
                  <Col>
                    <div className={styles["online-support-captcha"]}>
                      <LoadCanvasTemplate />
                    </div>
                  </Col>
                </Row>
                <Row justify="space-between" align={"middle"}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div>
                      <div className={styles["online-support-captcha-input"]}>
                        <Input
                          size="large"
                          placeholder={landingMessage.CAPTCHA_PLACEHOLDER[lang]}
                          value={dataState.captcha}
                          onChange={(e) => {
                            changeHandler("captcha", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              paddingBottom: "10px",
              margin: "auto",
              paddingBottom: "20px",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className={styles["Login-Button"]}
                onClick={(e) => {
                  loginHandler();
                }}
                disabled={loginLoading}
                loading={loginLoading}
              >
                {landingMessage.LOGIN[lang]}
              </Button>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className={styles["Cancel-Button"]}
                onClick={(e) => {
                  cancelHandler();
                }}
              >
                {landingMessage.CANCEL[lang]}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        title={landingMessage.FORGET_PASSWORD_MODAL_TITLE[lang]}
        visible={modalVisible}
        onOk={() => {
          toggleModal();
        }}
        onCancel={() => {
          toggleModal();
        }}
        cancelText={GeneralMessage.CANCEL[lang]}
        okText={GeneralMessage.OKAY[lang]}
      >
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <div>
            {landingMessage.FORGET_PASSWORD_MODAL_INPUT[lang]}
            <Input
              addonBefore="+852"
              value={modalState.telPhone || ""}
              onChange={(e) => modalChangeHandler("telPhone", e.target.value)}
            />
          </div>
          <Button
            type={"primary"}
            icon={<SendOutlined />}
            onClick={() => {
              sendSMS();
            }}
          >
            {landingMessage.FORGET_PASSWORD_MODAL_BUTTON[lang]}
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default Login;
