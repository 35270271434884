import { Row } from "antd";
import { useSelector } from "react-redux";
import { Button } from "antd";
import styles from "./webContent.module.scss";
import { WebContentMessage } from "../../../message/message";
import default_img from "../../../assets/img/web_content_default.png";
import { Link } from "react-router-dom";
import { convertDateForDisplay } from "../../../utils/Utils";
const WebContentDetail = (props) => {
  const lang = useSelector((state) => state.lang.value);

  const { webContent, type } = props;
  return (
    <div className={styles["web-content"]}>
      <div className={styles["web-content-back-button"]}>
        <Link to={`/${type}/`}>
          <Button>{WebContentMessage.BACK[lang]}</Button>
        </Link>
      </div>
      <div className={styles["web-content-title"]}>
        {lang == "en" ? webContent?.titleEn : webContent?.title}
      </div>
      <div>
        <img
          className={styles["web-content-img"]}
          src={webContent.url ? webContent.url : default_img}
        />
        <div style={{ textAlign: "start" }}>
          {convertDateForDisplay(webContent.creDt)}
        </div>
      </div>
      <div
        className={styles["web-content-body"]}
        dangerouslySetInnerHTML={{
          __html: lang == "en" ? webContent?.bodyEn : webContent?.body,
        }}
      ></div>
    </div>
  );
};

export default WebContentDetail;
