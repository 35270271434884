import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
  name: "lang",
  initialState: {
    value:
      document.cookie
        ?.split("rememberLang=")[1]
        ?.split(" ")[0]
        ?.replace(";", "") || "zh",
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
