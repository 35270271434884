import { headerMessage } from "../../message/message.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import { Switch } from "@progress/kendo-react-inputs";
import { changeLanguage } from "../../features/language/languageSlice";
import { Row, Col } from "antd/lib/grid";
import { useCookies } from "react-cookie";
import fbLogo from "../../assets/img/icons8-facebook-48.png";
import { ReportClient } from "../../service/web-api-client.ts";

const CustomHeader = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const [cookie, set_cookie] = useCookies();
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <Col flex="5">
          <div className="custom-header-content">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://hkphab.org.hk/en-gb/about-us-en/vision-and-mission-${
                lang == "zh" ? "ch" : lang
              }`}
            >
              <span className="custom-header-button">
                {headerMessage.ABOUT_US[lang]}
              </span>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://hkphab.org.hk/en-gb/archives/category/publication-en/annual-reports-and-financial-reports-${
                lang == "zh" ? "ch" : lang
              }`}
            >
              <span className="custom-header-button">
                {headerMessage.SHARING[lang]}
              </span>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://hkphab.org.hk/en-gb/archives/category/join-us-${
                lang == "zh" ? "ch" : lang
              }`}
            >
              <span className="custom-header-button">
                {headerMessage.JOIN_US[lang]}
              </span>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://hkphab.org.hk/en-gb/contact-us-${
                lang == "zh" ? "ch" : lang
              }`}
            >
              <span className="custom-header-button">
                {headerMessage.CONTACT_US[lang]}
              </span>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://digitalhub-admin.hkphab.org.hk/login`}
            >
              <span className="custom-header-button">
                {headerMessage.STAFF_LOGIN[lang]}
              </span>
            </a>
            <a
              onClick={() => {
                (async () => {
                  try {
                    const client = new ReportClient(
                      process.env.REACT_APP_API_BASE_URL
                    );
                    const result = await client.getApplicationForm();
                    const fileURL = window.URL.createObjectURL(result.data);
                    const fileDowwnloader = document.createElement("a");
                    fileDowwnloader.href = fileURL;
                    fileDowwnloader.setAttribute(
                      "download",
                      "NAD01_轉介表.pdf"
                    );
                    fileDowwnloader.click();
                  } catch (e) {
                    console.log(e);
                  }
                })();
              }}
            >
              <span className="custom-header-button">
                {headerMessage.DOWNLOAD_REGISTER_FORM[lang]}
              </span>
            </a>
          </div>
        </Col>
        <Col flex="1">
          <div className="custom-header-lang-switch">
            {/* <Switch
              onLabel={"中"}
              defaultChecked={lang == "zh"}
              offLabel={"Eng"}
              size={"large"}
              trackRounded={"medium"}
              thumbRounded={"medium"}
              onChange={(e) => {
                dispatch(changeLanguage(e.value ? "zh" : "en"));
                set_cookie("rememberLang", e.value ? "zh" : "en", {
                  path: "/",
                });
              }}
            /> */}
            <span
              className={`lang-switcher ${lang == "zh" ? "active" : ""}`}
              onClick={() => {
                dispatch(changeLanguage("zh"));
                set_cookie("rememberLang", "zh", {
                  path: "/",
                });
              }}
            >
              繁
            </span>
            <span
              className={`lang-switcher ${lang == "en" ? "active" : ""}`}
              onClick={() => {
                dispatch(changeLanguage("en"));
                set_cookie("rememberLang", "en", {
                  path: "/",
                });
              }}
            >
              EN
            </span>
            <img
              src={fbLogo}
              alt={"Facebook"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window
                  .open(
                    "https://www.facebook.com/pages/category/ngo/%E8%B3%BD%E9%A6%AC%E6%9C%83%E5%8B%95-%E7%B5%A1%E6%96%B0%E5%82%B7%E6%AE%98%E4%BA%BA%E5%A3%AB%E6%94%AF%E6%8F%B4%E8%A8%88%E5%8A%83-108988781453267/ ",
                    "_blank"
                  )
                  .focus();
              }}
            ></img>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomHeader;
