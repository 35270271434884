import logo from "../../assets/img/phab-logo.png";

import nadLogo from "../../assets/img/nad-logo.png";
import { Row, Col } from "antd/lib/grid";
import { Link, useNavigate } from "react-router-dom";
import { isLoggedIn, logout } from "../../utils/Utils";
import { subHeaderMessage } from "../../message/message";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { useCookies } from "react-cookie";

const SubHeader = (props) => {
  const lang = useSelector((state) => state.lang.value);

  const nav = useNavigate();

  const logoutHandler = () => {
    (async () => {
      await logout();
      window.location.href = "/Login";
    })();
  };

  return (
    <Row className="sub-header" justify="space-between" align="middle">
      <Col flex="auto">
        <Link to={"/"}>
          <img height={"53vw"} src={nadLogo} alt={"PHAB"}></img>
        </Link>
      </Col>
      <Col style={{ textAlign: "end" }} flex="auto">
        {isLoggedIn() ? (
          <Button onClick={() => logoutHandler()} danger>
            {subHeaderMessage.LOGOUT[lang]}
          </Button>
        ) : (
          <Button onClick={() => (window.location.href = "/Login")} danger>
            {subHeaderMessage.LOGIN[lang]}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default SubHeader;
