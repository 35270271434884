import { createSlice } from "@reduxjs/toolkit";

export const siderSlice = createSlice({
  name: "sider",
  initialState: {
    visible: false,
    activeItem: null,
  },
  reducers: {
    showSider: (state, action) => {
      state.visible = true;
    },
    hideSider: (state, action) => {
      state.visible = false;
    },
    changeActiveItem: (state, action) => {
      state.activeItem = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSider, hideSider, changeActiveItem } = siderSlice.actions;

export default siderSlice.reducer;
