import { Row, Col, Card } from "antd";
import { memberProfileMessage } from "../../../message/message";
import styles from "../MemberModule/member.module.scss";
export const MemberProfileViewPage = (props) => {
  const { Lang, GetMemberDate } = props;
  return (
    <>
      <div className={styles["profile-background"]}>
        <div className={styles["profile-title"]}>
          {memberProfileMessage.MemberProfile_title[Lang]}
        </div>
        <Card>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <div className={styles["profile-label-title"]}>
                {memberProfileMessage.MemberProfile_nameCn[Lang]}
              </div>
            </Col>
            <Col xl={10} lg={10} md={10} sm={10} xs={12}>
              <div className={styles["profile-label-content"]}>
                {GetMemberDate()?.nameCn}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <div className={styles["profile-label-title"]}>
                {memberProfileMessage.MemberProfile_nameEn[Lang]}
              </div>
            </Col>
            <Col xl={10} lg={10} md={10} sm={10} xs={12}>
              <div className={styles["profile-label-content"]}>
                {GetMemberDate()?.firstNameEn} {GetMemberDate()?.lastNameEn}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <div className={styles["profile-label-title"]}>
                {memberProfileMessage.MemberProfile_TelePhone[Lang]}
              </div>
            </Col>
            <Col xl={10} lg={10} md={10} sm={10} xs={12}>
              <div className={styles["profile-label-content"]}>
                {GetMemberDate()?.telPhone}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <div className={styles["profile-label-title"]}>
                {memberProfileMessage.MemberProfile_Sex[Lang]}
              </div>
            </Col>
            <Col xl={10} lg={10} md={10} sm={10} xs={12}>
              <div className={styles["profile-label-content"]}>
                {GetMemberDate()?.sex == "M" ? "男" : "女"}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <div className={styles["profile-label-title"]}>
                {memberProfileMessage.MemberProfile_Age[Lang]}
              </div>
            </Col>
            <Col xl={10} lg={10} md={10} sm={10} xs={12}>
              <div className={styles["profile-label-content"]}>
                {GetMemberDate()?.age}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <div className={styles["profile-label-title"]}>
                {memberProfileMessage.MemberProfile_Address[Lang]}
              </div>
            </Col>
            <Col xl={10} lg={10} md={10} sm={10} xs={12}>
              <div className={styles["profile-label-content"]}>
                {GetMemberDate()?.fullAddress}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <div className={styles["profile-label-title"]}>
                {memberProfileMessage.MemberProfile_IsHealth[Lang]}
              </div>
            </Col>
            <Col xl={10} lg={10} md={10} sm={10} xs={12}>
              <div className={styles["profile-label-content"]}>
                {GetMemberDate()?.injuries.length > 0 ? "是" : "否"}
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
