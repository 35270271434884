import { createSlice } from "@reduxjs/toolkit";

export const memberSlice = createSlice({
  name: "member",
  initialState: {
    headerVisible: false,
    info: {
      nameEn: null,
      nameZh: null,
      memberNum: null,
      haveNewVideo: false,
    },
  },
  reducers: {
    showMemberHeader: (state, action) => {
      state.headerVisible = true;
    },
    hideMemberHeader: (state, action) => {
      state.headerVisible = false;
    },
    setMember: (state, action) => {
      state.info = action.payload;
    },
    resetMember: (state, action) => {
      state.info = {
        nameEn: null,
        nameZh: null,
        memberNum: null,
        haveNewVideo: false,
      };
    },
    updateHaveNewVideo: (state, action) => {
      state.info.haveNewVideo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showMemberHeader,
  hideMemberHeader,
  setMember,
  resetMember,
  updateHaveNewVideo,
} = memberSlice.actions;

export default memberSlice.reducer;
