import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { notification, Spin } from "antd";
import { useDispatch } from "react-redux";
import { showSider, changeActiveItem } from "../../features/sider/siderSlice";
import { showMemberHeader } from "../../features/member/memberSlice";

import { useSelector } from "react-redux";
import { MemberVideoMessage, landingMessage } from "../../message/message";
import { AddNewVideo } from "../../component/custom/MemberVideo/AddNewVideo";
import { KeepVideo } from "../../component/custom/MemberVideo/KeepVideo";
import { CompletedVideo } from "../../component/custom/MemberVideo/CompletedVideo";
import styles from "./member.module.scss";
import { ClientAPIClient } from "../../service/web-api-client.ts";
import { convertDateTimeForDisplay, convertDateForAPI } from "../../auth/utils";

import { useCookies } from "react-cookie";

const MemberVideo = (props) => {
  const [cookie, set_cookie] = useCookies(["rememberUserName"]);
  const { PageCode } = useParams();
  const lang = useSelector((state) => state.lang.value);
  const [filterState, set_filterState] = useState(PageCode);
  const [newState, set_newState] = useState(true);
  const [keepState, set_keepState] = useState(false);
  const [completedState, set_completedState] = useState(false);
  const [videoList, set_videoList] = useState([]);
  const [isLoading, set_isLoading] = useState(false);

  const dispatch = useDispatch();
  const displayContent = (mode) => {
    set_cookie("PageCode", mode, { path: "/" });
    switch (mode) {
      case "NewVideo":
        set_newState(true);
        set_keepState(false);
        set_completedState(false);
        break;
      case "Continue":
        set_newState(false);
        set_keepState(true);
        set_completedState(false);
        break;
      case "CompletedVideo":
        set_newState(false);
        set_keepState(false);
        set_completedState(true);
        break;
    }
    set_filterState(mode);
  };
  useEffect(() => {
    if (PageCode) {
      displayContent(PageCode);
    } else if (cookie.PageCode) {
      displayContent(cookie.PageCode);
    } else {
      displayContent("NewVideo");
    }
    set_isLoading(true);

    dispatch(showSider());
    dispatch(showMemberHeader());
    dispatch(changeActiveItem("MY_VIDEO")); // refer to src/component/common/CustomSider.js
    (async () => {
      try {
        const ClientAPI_Client = new ClientAPIClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const result_ClientAPI = await ClientAPI_Client.getVideoAndVideoCat();
        //console.log("result_ClientAPI", result_ClientAPI);
        set_videoList(refineAPIData(result_ClientAPI));
        set_isLoading(false);
      } catch (e) {
        notification.error({
          message: landingMessage.ERROR[lang],
        });
        console.log(e);
      }
    })();
  }, [lang]);
  const refineAPIData = (APIData) => {
    const result = APIData.map((item) => {
      return {
        ...item,
        VimeoId: item.url,
        creDt: `${convertDateTimeForDisplay(convertDateForAPI(item.creDt))}`,
      };
    });
    return result;
  };
  return (
    <div>
      {/* <div>Member Video</div> */}
      <Spin spinning={isLoading}>
        <div className={`d-flex ${styles["member-video-background"]}`}>
          <ButtonGroup className={styles["custom-btn-group"]}>
            <Button
              className={filterState === "NewVideo" ? styles["active"] : null}
              variant="secondary"
              active={filterState === "NewVideo"}
              onClick={() => {
                displayContent("NewVideo");
              }}
            >
              {MemberVideoMessage.AddNewMovie[lang]}
            </Button>

            <Button
              className={filterState === "Continue" ? styles["active"] : null}
              variant="secondary"
              active={filterState === "Continue"}
              onClick={() => {
                displayContent("Continue");
              }}
            >
              {MemberVideoMessage.KeepWatch[lang]}
            </Button>

            <Button
              className={
                filterState === "CompletedVideo" ? styles["active"] : null
              }
              variant="secondary"
              active={filterState === "CompletedVideo"}
              onClick={() => {
                displayContent("CompletedVideo");
              }}
            >
              {MemberVideoMessage.CompletedMovie[lang]}
            </Button>
          </ButtonGroup>
        </div>
        {newState && (
          <>
            <AddNewVideo
              Lang={lang}
              videoList={videoList?.filter((e) => e.progress == "New")}
            />
          </>
        )}
        {keepState && (
          <>
            <KeepVideo
              Lang={lang}
              videoList={videoList?.filter((e) => e.progress == "Continue")}
            />
          </>
        )}
        {completedState && (
          <>
            <CompletedVideo
              Lang={lang}
              videoList={videoList?.filter((e) => e.progress == "Complete")}
            />
          </>
        )}
      </Spin>
    </div>
  );
};

export default MemberVideo;
