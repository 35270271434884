// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_API_AUTH_URL}/Login`,
  registerEndpoint: `${process.env.REACT_APP_API_AUTH_URL}/register`,
  refreshEndpoint: `${process.env.REACT_APP_API_AUTH_URL}/refresh-token`,
  logoutEndpoint: `${process.env.REACT_APP_API_AUTH_URL}/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken", // following the name of the API payload
  storageRefreshTokenKeyName: "refreshToken", // following the name of the API payload
  storageUserNameKeyName: "userName",
};
