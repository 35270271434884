import { Row, Col, notification, Button } from "antd";
import { memberProfileMessage } from "../../../message/message";
import {
  landingMessage,
  memberHomeListingPage,
  MemberVideoMessage,
} from "../../../message/message";
import styles from "../MemberModule/member.module.scss";
import { VimeoThumbnail } from "../../common/VimeoThumbnail";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSider } from "../../../features/sider/siderSlice";
import { changeActiveItem } from "../../../features/sider/siderSlice";
import { ClientAPIClient } from "../../../service/web-api-client.ts";

export const MemberViewVideoCatViewPage = (props) => {
  const dispatch = useDispatch();
  const [startVideoMarked, set_startVideoMarked] = useState(-1);

  const { lang, videoList } = props;
  const rowRedirect = "/ViewVideo/";
  const navigate = useNavigate();
  const ToUrl = (VimeoId, VimeoCatId) => {
    // console.log("VimeoId", VimeoId);
    navigate(rowRedirect + VimeoCatId + "/" + VimeoId);
  };
  const GetName = (videoName) => {
    // console.log("videoName", videoName);
    // if (videoName.length > 18) {
    //   if (lang == "en") {
    //     return videoName.substr(0, 20) + "...";
    //   } else {
    //     return videoName.substr(0, 8) + "...";
    //   }
    // } else {
    //   return videoName;
    // }
    return videoName;
  };
  const GetDescription = (Description) => {
    if (!Description) {
      return "";
    }
    if (Description.length > 18) {
      if (lang == "en") {
        return Description.substr(0, 80) + "...";
      } else {
        return Description.substr(0, 40) + "...";
      }
    } else {
      return Description;
    }
  };

  const questionButtonClickHandler = (videoId, url) => {
    (async () => {
      const clientAPIClient = new ClientAPIClient(
        process.env.REACT_APP_API_BASE_URL
      );
      await clientAPIClient.updateQuestionnaireCompleteness({
        videoId,
        isQuestionnaireComplete: true,
      });
      window.open(url, "_blank").focus();
    })();
  };

  useEffect(() => {
    dispatch(showSider());
    dispatch(changeActiveItem()); // refer to src/component/common/CustomSider.js
    for (let i = 0; i < videoList.videos.length; i++) {
      if (!videoList.videos[i].isComplete) {
        set_startVideoMarked(i);
        break;
      }
    }
  }, []);

  return (
    <>
      <div className={styles["profile-background"]}>
        <div className={styles["cat-online-support-session"]}>
          <Row style={{ paddingBottom: "10vh" }}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className={styles["label-title"]}>
                {lang == "en"
                  ? videoList.videoCategoryNameEn
                  : videoList.videoCategoryName}
              </div>

              {videoList.videos.map((item, idx) => (
                <Row
                  style={{
                    paddingBottom: "10vh",
                    margin: "auto",
                    // padding: "20vh",
                  }}
                >
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <VimeoThumbnail vimeoId={item.VimeoId} size={"large"} />
                    </div>
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={24}
                    xs={24}
                    style={{ paddingLeft: "1vw" }}
                  >
                    <div className={styles["cat-video-title"]}>
                      <span
                        title={lang == "en" ? item.videoNameEn : item.videoName}
                      >
                        {GetName(
                          lang == "en" ? item.videoNameEn : item.videoName
                        )}
                      </span>
                    </div>
                    <div className={styles["cat-video-desc"]}>
                      <span
                        title={
                          lang == "en"
                            ? item.vedioDescriptionEn
                            : item.videoDescription
                        }
                      >
                        {GetDescription(
                          lang == "en"
                            ? item.vedioDescriptionEn
                            : item.videoDescription
                        )}
                      </span>
                      {/* {lang == "en" ? item.vedioDescriptionEn : item.videoDescription} */}
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    {item.isComplete && (
                      <div style={{ paddingBottom: "10px" }}>
                        <div className={styles["icon-flex-column"]}>
                          <svg
                            style={{
                              width: "1em",
                              height: "1em",
                              cursor: "pointer",
                              paddingTop: "0.2em",
                            }}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2154"
                          >
                            <path
                              d="M529.523831 48.306192c-248.375025 0-449.722041 201.338564-449.722041 449.703165s201.347015 449.703165 449.722041 449.703165 449.722041-201.338564 449.722041-449.703165S777.898856 48.306192 529.523831 48.306192zM747.172798 477.358015 525.78349 698.738032c-11.277308 11.276834-26.081076 16.841573-40.862332 16.758686-14.781255 0.083911-29.586047-5.481851-40.863355-16.758686L327.279338 581.964468c-22.387809-22.387893-22.387809-58.685587 0-81.072457 22.388833-22.388916 58.68805-22.388916 81.07586 0l76.56596 76.561723L666.095915 396.285558c22.388833-22.388916 58.68805-22.388916 81.07586 0C769.561631 418.673451 769.561631 454.971146 747.172798 477.358015z"
                              p-id="2155"
                            ></path>
                          </svg>
                          <span>{MemberVideoMessage.CompletedMovie[lang]}</span>
                        </div>
                      </div>
                    )}

                    {item.isquestionnaireComplete && (
                      <div style={{ paddingBottom: "10px" }}>
                        <div className={styles["icon-flex-column"]}>
                          <svg
                            style={{
                              width: "1em",
                              height: "1em",
                              cursor: "pointer",
                              paddingTop: "0.2em",
                            }}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2154"
                          >
                            <path
                              d="M529.523831 48.306192c-248.375025 0-449.722041 201.338564-449.722041 449.703165s201.347015 449.703165 449.722041 449.703165 449.722041-201.338564 449.722041-449.703165S777.898856 48.306192 529.523831 48.306192zM747.172798 477.358015 525.78349 698.738032c-11.277308 11.276834-26.081076 16.841573-40.862332 16.758686-14.781255 0.083911-29.586047-5.481851-40.863355-16.758686L327.279338 581.964468c-22.387809-22.387893-22.387809-58.685587 0-81.072457 22.388833-22.388916 58.68805-22.388916 81.07586 0l76.56596 76.561723L666.095915 396.285558c22.388833-22.388916 58.68805-22.388916 81.07586 0C769.561631 418.673451 769.561631 454.971146 747.172798 477.358015z"
                              p-id="2155"
                            ></path>
                          </svg>
                          <span>
                            {MemberVideoMessage.CompletedQuestionnaire[lang]}
                          </span>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row>
                      {item.isComplete && (
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Button
                            className={styles["button-watch-style"]}
                            onClick={() => {
                              ToUrl(item.sysId, videoList.sysId);
                            }}
                          >
                            {memberHomeListingPage.ReWatch[lang]}
                          </Button>
                        </Col>
                      )}
                      {item.isComplete && false && (
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Button
                            className={styles["button-watch-style"]}
                            onClick={() => {
                              ToUrl(item.sysId, videoList.sysId);
                            }}
                          >
                            {memberHomeListingPage.Questionnaire[lang]}
                          </Button>
                        </Col>
                      )}
                      {!item.isComplete && (
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Button
                            disabled={!(idx == startVideoMarked)}
                            className={
                              idx == startVideoMarked
                                ? styles["button-watch-style"]
                                : styles["button-watch-style-disabled"]
                            }
                            onClick={() => {
                              ToUrl(item.sysId, videoList.sysId);
                            }}
                          >
                            {memberHomeListingPage.Watch[lang]}
                          </Button>
                        </Col>
                      )}
                      {item.isComplete && !item.isquestionnaireComplete && (
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Button
                            className={styles["button-watch-style"]}
                            onClick={() => {
                              questionButtonClickHandler(
                                item.sysId,
                                item.questionnaire
                              );
                            }}
                          >
                            {memberHomeListingPage.Questionnaire[lang]}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
