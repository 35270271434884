import { AuthenticateClient } from "../service/web-api-client.ts";
import { notification } from "antd";
import { landingMessage } from "../message/message";

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

export const logout = async () => {
  try {
    const authClient = new AuthenticateClient(
      process.env.REACT_APP_API_BASE_URL
    );
    await authClient.revokeToken();
    // TODO need to set premission for this API revokeToken
  } catch (e) {
    console.log(e);
  }
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userData");
  console.log("Logout successfully");
  // window.location.href = "/Login";
};

export const isLoggedIn = () => {
  if (getUserData()) {
    return true;
  }
  return false;
};

export const getMemberTelPhone = () => {
  return getUserData()?.telPhone;
};

export const getIsUseWhatsapp = () => {
  return getUserData()?.isUseWhatsapp;
};

export const createCuePoint = async (lang, player, duration) => {
  for (let i = 0; i < 10; i++) {
    await player
      .addCuePoint(duration * (i * 0.1 + 0.05), { cuePoint: i })
      .then(function (id) {
        // The cue point is added
      })
      .catch(function (error) {
        // console.log(typeof error);
        switch (error.name) {
          case "UnsupportedError":
            console.log("UnsupportedError", error);
            break;
          // Cue points aren't supported by the current player or browser
          case "RangeError":
            console.log("RangeError", error);
            break;

          // The time is less than 0 or greater than the video's duration
          default:
            console.log("unknown Error", error);
          // Some other error occurred
        }
        return notification.error({
          message: landingMessage.NETWORK_ERROR[lang],
        });
      });
  }
};

export const strToDateConverter = (dateString) => {
  // dateString should be in format yyyy-MM-dd
  if (typeof dateString == "object") {
    return dateString;
  }
  return new Date(dateString);
};

export const dateToStrConverter = (dateObject) => {
  const mm = dateObject.getMonth() + 1; // getMonth() is zero-based
  const dd = dateObject.getDate();

  return [
    dateObject.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("-");
};

export const timeToStrConverter = (dateObject) => {
  const hh = dateObject.getHours(); // getMonth() is zero-based
  const mm = dateObject.getMinutes();
  const ss = dateObject.getSeconds();

  const timeResult = [
    (hh > 9 ? "" : "0") + hh,
    (mm > 9 ? "" : "0") + mm,
    (ss > 9 ? "" : "0") + ss,
  ].join(":");
  return `${timeResult}.${dateObject.getMilliseconds()}`;
};

export const simpleTimeToStrConverter = (dateObject) => {
  if (dateObject) {
    const hh = dateObject.getHours(); // getMonth() is zero-based
    const mm = dateObject.getMinutes();
    const ss = dateObject.getSeconds();

    const timeResult = [
      (hh > 9 ? "" : "0") + hh,
      (mm > 9 ? "" : "0") + mm,
      (ss > 9 ? "" : "0") + ss,
    ].join(":");
    return timeResult;
  } else {
    return "";
  }
};

export const dateToFullStringConverter = (dateObject) => {
  return `${dateToStrConverter(dateObject)}T${timeToStrConverter(dateObject)}Z`;
};
export const getLocalDate = (dateObject) => {
  if (typeof dateObject == "object") {
    const tz = new Date(dateObject).getTimezoneOffset() * 60000;
    return new Date(dateObject.getTime() - tz);
  } else {
    return null;
  }
};

function addHours(numOfHours, date) {
  if (date) {
    const secondToAdd = numOfHours * 3600000;
    date.setTime(date.getTime() + secondToAdd);

    return date;
  }
  return null;
}

export const convertDateForAPI = (dateObject) => {
  if (dateObject && typeof dateObject == "object") {
    return addHours(8, dateObject);
  } else if (typeof dateObject == "string") {
    return new Date(`${dateObject.replace("Z", "")}Z`);
  }
  return dateObject;
};

export const convertDateForDisplay = (dateObject) => {
  if (dateObject && typeof dateObject == "object") {
    return dateToStrConverter(dateObject);
  } else if (
    dateObject &&
    typeof dateObject == "string" &&
    dateObject.includes("T")
  ) {
    const newdateObject = new Date(dateObject);
    return dateToStrConverter(newdateObject);
  }
  return dateObject;
};

export const convertDateTimeForDisplay = (dateObject) => {
  if (dateObject && typeof dateObject == "object") {
    return `${dateToStrConverter(dateObject)} ${simpleTimeToStrConverter(
      dateObject
    )}`;
  } else if (
    dateObject &&
    typeof dateObject == "string" &&
    dateObject.includes("T")
  ) {
    const newdateObject = new Date(dateObject);
    return `${dateToStrConverter(newdateObject)} ${simpleTimeToStrConverter(
      newdateObject
    )}`;
  }
  return dateObject;
};

export const getCurrentYear = () => {
  const thisMoment = new Date();
  return thisMoment.getFullYear();
};

export const getCurrentMonth = () => {
  const thisMoment = new Date();
  return thisMoment.getMonth() + 1;
};
