import TextArea from "antd/lib/input/TextArea";

const CommentModalContent = (props) => {
  const { content, changeHandler } = props;
  return (
    <div>
      <TextArea
        value={content}
        onChange={(e) => {
          changeHandler("content", e.target.value);
        }}
        rows={10}
        autoSize={{ minRows: 10, maxRows: 10 }}
      />
    </div>
  );
};

export default CommentModalContent;
