import { headerMessage } from "../../message/message.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import { Switch } from "@progress/kendo-react-inputs";
import { changeLanguage } from "../../features/language/languageSlice";
import { Row, Col } from "antd/lib/grid";
import { Dropdown, Menu } from "antd";
import { useCookies } from "react-cookie";
import { MenuOutlined } from "@ant-design/icons";
import { Facebook } from "react-feather";
import { ReportClient } from "../../service/web-api-client.ts";
import fbLogo from "../../assets/img/icons8-facebook-48.png";
const menu = (lang) => (
  <Menu
    items={[
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hkphab.org.hk/en-gb/about-us-en/vision-and-mission-en"
          >
            {headerMessage.ABOUT_US[lang]}
          </a>
        ),
        key: "0",
      },
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hkphab.org.hk/en-gb/archives/category/publication-en/annual-reports-and-financial-reports-en"
          >
            {headerMessage.SHARING[lang]}
          </a>
        ),
        key: "1",
      },
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hkphab.org.hk/en-gb/archives/category/join-us-en"
          >
            {headerMessage.JOIN_US[lang]}
          </a>
        ),
        key: "2",
      },
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hkphab.org.hk/en-gb/contact-us-en"
          >
            {headerMessage.CONTACT_US[lang]}
          </a>
        ),
        key: "3",
      },
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://stphabvideoadminuat.z7.web.core.windows.net/login"
          >
            {headerMessage.STAFF_LOGIN[lang]}
          </a>
        ),
        key: "4",
      },
      {
        label: (
          <span
            onClick={() => {
              (async () => {
                try {
                  const client = new ReportClient(
                    process.env.REACT_APP_API_BASE_URL
                  );
                  const result = await client.getApplicationForm();
                  const fileURL = window.URL.createObjectURL(result.data);
                  const fileDowwnloader = document.createElement("a");
                  fileDowwnloader.href = fileURL;
                  fileDowwnloader.setAttribute("download", "NAD01_轉介表.pdf");
                  fileDowwnloader.click();
                } catch (e) {
                  console.log(e);
                }
              })();
            }}
          >
            {headerMessage.DOWNLOAD_REGISTER_FORM[lang]}
          </span>
        ),
        key: "5",
      },
    ]}
  />
);

const CustomHeaderMobile = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const dispatch = useDispatch();
  const [cookie, set_cookie] = useCookies();
  return (
    <>
      <Row>
        <Col flex="5">
          <div className="custom-header-mobile-content">
            <Dropdown overlay={menu(lang)} trigger={["click"]}>
              <MenuOutlined />
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div className="custom-header-lang-switch">
            {/* <Switch
              defaultChecked={lang == "zh"}
              onLabel={"中"}
              offLabel={"Eng"}
              size={"large"}
              trackRounded={"medium"}
              thumbRounded={"medium"}
              onChange={(e) => {
                set_cookie("rememberLang", e.value ? "zh" : "en", {
                  path: "/",
                });
                dispatch(changeLanguage(e.value ? "zh" : "en"));
              }}
            /> */}
            <span
              className={`lang-switcher ${lang == "zh" ? "active" : ""}`}
              onClick={() => {
                dispatch(changeLanguage("zh"));
                set_cookie("rememberLang", "zh", {
                  path: "/",
                });
              }}
            >
              繁
            </span>
            <span
              className={`lang-switcher ${lang == "en" ? "active" : ""}`}
              onClick={() => {
                dispatch(changeLanguage("en"));
                set_cookie("rememberLang", "en", {
                  path: "/",
                });
              }}
            >
              EN
            </span>
            <span
              className="facebook-navigator"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window
                  .open(
                    "https://www.facebook.com/pages/category/ngo/%E8%B3%BD%E9%A6%AC%E6%9C%83%E5%8B%95-%E7%B5%A1%E6%96%B0%E5%82%B7%E6%AE%98%E4%BA%BA%E5%A3%AB%E6%94%AF%E6%8F%B4%E8%A8%88%E5%8A%83-108988781453267/ ",
                    "_blank"
                  )
                  .focus();
              }}
            >
              <img src={fbLogo} alt={"Facebook"}></img>
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomHeaderMobile;
