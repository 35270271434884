import { Row, Col, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { VimeoThumbnail } from "../../common/VimeoThumbnail";
import { memberHomeListingPage } from "../../../message/message";
import styles from "../MemberModule/member.module.scss";
import { ClientAPIClient } from "../../../service/web-api-client.ts";

export const VideoItem = (props) => {
  const {
    Lang,
    VimeoId,
    IsCompleted = false,
    IsCat = false,
    videoItem,
  } = props;
  const rowRedirect = "/ViewVideo/";
  const rowRedirectCat = "/ViewVideoCat/";
  const navigate = useNavigate();
  const ToUrl = () => {
    // console.log("VimeoId", VimeoId);
    // window.location.href="https://vimeo.com/"+VimeoId;
    if (IsCat == true) {
      navigate(rowRedirectCat + videoItem.videoCatId);
    } else {
      navigate(rowRedirect + videoItem.videoId);
    }
  };
  const ToQUrl = () => {
    (async () => {
      const clientAPIClient = new ClientAPIClient(
        process.env.REACT_APP_API_BASE_URL
      );
      await clientAPIClient.updateQuestionnaireCompleteness({
        videoId: videoItem.videoId,
        isQuestionnaireComplete: true,
      });
      window.open(videoItem.questionnaire, "_blank").focus();
    })();
  };
  const GetName = (videoName) => {
    // if (videoName.length > 18) {
    //   if (Lang == "en") {
    //     return videoName.substr(0, 40) + "...";
    //   }
    //   return videoName.substr(0, 15) + "...";
    // }
    return videoName;

    // return videoName;
  };
  return (
    <>
      <Col
        xl={6}
        lg={8}
        md={12}
        sm={12}
        xs={24}
        style={{ padding: "0 1vw 3vh 1vw", textAlign: "start" }}
      >
        {IsCat ? (
          <div style={{ position: "relative" }}>
            <div className={styles["VimeoThumbnail-div-black-style"]}>
              {/* <span>10</span> */}
              <svg
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="bars"
                width="2em"
                height="2em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0z"></path>
              </svg>
            </div>
            <VimeoThumbnail vimeoId={VimeoId} size={"large"} />
          </div>
        ) : (
          <VimeoThumbnail vimeoId={VimeoId} size={"large"} />
        )}
        {IsCompleted ? (
          <div>
            <Button className={styles["button-watch-style"]} onClick={ToUrl}>
              {memberHomeListingPage.ReWatch[Lang]}
            </Button>
            {!IsCat && videoItem.questionnaire != null && (
              <Button
                className={styles["button-watch-style"]}
                onClick={() => {
                  ToQUrl();
                }}
              >
                {memberHomeListingPage.Questionnaire[Lang]}
              </Button>
            )}
          </div>
        ) : (
          <Button className={styles["button-watch-style"]} onClick={ToUrl}>
            {memberHomeListingPage.Watch[Lang]}
          </Button>
        )}
        <div className="my-2 " style={{ textAlign: "start" }}>
          <span title={Lang == "en" ? videoItem.nameEn : videoItem.nameCn}>
            {GetName(Lang == "en" ? videoItem.nameEn : videoItem.nameCn)}
          </span>
        </div>
        <div className="my-2" style={{ textAlign: "start" }}>
          {videoItem.creDt}
        </div>
      </Col>
    </>
  );
};
