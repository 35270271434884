import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSider, changeActiveItem } from "../../features/sider/siderSlice";
import { showMemberHeader } from "../../features/member/memberSlice";
import { useSelector } from "react-redux";
import { siderMessage } from "../../message/message";
import { MemberProfileViewPage } from "../../component/custom/MemberProfile/MemberProfileViewPage";
import styles from "./member.module.scss";
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
//import { OnlineSupport } from "../component/custom/Landing/OnlineSupport";

const MemberProfile = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const dispatch = useDispatch();
  //const [memberDate, set_memberDate] = useState([]);

  useEffect(() => {
    dispatch(showSider());
    dispatch(showMemberHeader());
    dispatch(changeActiveItem("MY_PROFILE")); // refer to src/component/common/CustomSider.js
  });
  const GetMemberDate = () => {
    const currentUserData = getUserData();
    return currentUserData;
    //set_memberDate(currentUserData)
  };
  return (
    <div className={styles["member-background"]}>
      <MemberProfileViewPage Lang={lang} GetMemberDate={GetMemberDate} />
    </div>
  );
};

export default MemberProfile;
