import { useParams } from "react-router-dom";
import { notification, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { landingMessage } from "../../message/message";
import { VideoViewPage } from "../../component/custom/VideoPage/VideoViewPage";
import { useSelector } from "react-redux";
import { ClientAPIClient } from "../../service/web-api-client.ts";
import { convertDateTimeForDisplay, convertDateForAPI } from "../../auth/utils";
import { showMemberHeader } from "../../features/member/memberSlice";
import { showSider, changeActiveItem } from "../../features/sider/siderSlice";

const MemberViewVideo = (props) => {
  const lang = useSelector((state) => state.lang.value);
  const [isLoading, set_isLoading] = useState(false);
  const { video_id, video_cat_id } = useParams();
  const [videoModel, set_videoModel] = useState(null);
  const [videoList, set_videoList] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    set_isLoading(true);
    dispatch(showSider());
    dispatch(changeActiveItem()); // refer to src/component/common/CustomSider.js
    dispatch(showMemberHeader());
    (async () => {
      try {
        const VideoAPI_Client = new ClientAPIClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const Video_ClientAPI = await VideoAPI_Client.getVideoByVideoId(
          video_id,
          video_cat_id ? video_cat_id : null
        );
        // console.log("Video_ClientAPI", Video_ClientAPI);
        if (Video_ClientAPI.message == "Fail") {
          notification.error({
            message: landingMessage.Only_Selected_Members[lang],
          });
          window.history.back(-1);
        } else {
          set_videoModel(refineAPIData(Video_ClientAPI.video));
        }

        const result_ClientAPI = await VideoAPI_Client.getVideoCatBySysId(
          video_cat_id
        );
        // console.log("result_ClientAPI", result_ClientAPI);
        set_videoList(refineCatAPIData(result_ClientAPI));
        set_isLoading(false);
      } catch (e) {
        notification.error({
          message: landingMessage.ERROR[lang],
        });
        console.log(e);
      }
    })();
  }, [video_id]);
  const refineAPIData = (APIData) => {
    const result = {
      ...APIData,
      VimeoId: APIData.videoUrl,
      creDt: `${convertDateTimeForDisplay(convertDateForAPI(APIData.creDt))}`,
    };
    // console.log("result_ClientAPI", result);
    return result;
  };
  const refineCatAPIData = (APIData) => {
    const result_videos = APIData.videoCat?.videos?.map((item) => {
      return {
        ...item,
        VimeoId: item.videoUrl,
        creDt: `${convertDateTimeForDisplay(convertDateForAPI(item.creDt))}`,
      };
    });
    const result = {
      ...APIData.videoCat,
      videos: result_videos ? [...result_videos] : [],
    };
    // console.log("result_CatClientAPI", result);
    return result;
  };
  return (
    <>
      {/* <Link to={ToVideo("7334809551")}>123</Link> */}
      <Spin spinning={isLoading}>
        {(video_cat_id ? videoList && videoModel : videoModel) && (
          <VideoViewPage
            videoModel={videoModel}
            videoList={videoList}
            VimeoId={videoModel.VimeoId}
            lang={lang}
            IsCat={video_cat_id ? true : false}
            videoParam={video_id}
          />
        )}
      </Spin>
    </>
  );
};

export default MemberViewVideo;
