import { Row, Col, Input, Checkbox, Button, notification, Spin } from "antd";
import { useEffect, useState } from "react";
import {
  siderMessage,
  MemberVideoMessage,
  landingMessage,
} from "../../message/message";
import { useParams, Link } from "react-router-dom";
import { MemberViewVideoCatViewPage } from "../../component/custom/MemberViewVideoCatPage/MemberViewVideoCatViewPage";
import { useSelector } from "react-redux";
import { ClientAPIClient } from "../../service/web-api-client.ts";
import {
  dateToStrConverter,
  convertDateTimeForDisplay,
  convertDateForAPI,
} from "../../auth/utils";

const MemberViewVideoCat = (props) => {
  const [isLoading, set_isLoading] = useState(false);
  const { video_cat_id } = useParams();
  const lang = useSelector((state) => state.lang.value);
  const [videoList, set_videoList] = useState(null);
  useEffect(() => {
    set_isLoading(true);
    (async () => {
      try {
        const ClientAPI_Client = new ClientAPIClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const result_ClientAPI = await ClientAPI_Client.getVideoCatBySysId(
          video_cat_id
        );
        // console.log("result_ClientAPI", result_ClientAPI);
        set_videoList(refineAPIData(result_ClientAPI));
        set_isLoading(false);
      } catch (e) {
        notification.error({
          message: landingMessage.ERROR[lang],
        });
        console.log(e);
      }
    })();
  }, []);
  const refineAPIData = (APIData) => {
    const result_videos = APIData.videoCat.videos.map((item) => {
      return {
        ...item,
        VimeoId: item.videoUrl,
        creDt: `${convertDateTimeForDisplay(convertDateForAPI(item.creDt))}`,
      };
    });
    const result = {
      ...APIData.videoCat,
      videos: [...result_videos],
    };

    // const result = APIData.videoCat.videos.map((item) => {
    //   return {
    //     ...item,
    //     VimeoId: item.videoUrl ? item.videoUrl.split('/')[item.videoUrl.split('/').length - 1] : "",
    //     creDt: `${convertDateTimeForDisplay(convertDateForAPI(item.creDt))}`,
    //   };
    // });
    // console.log("result_ClientAPI", result);

    return result;
  };
  return (
    <>
      <Spin spinning={isLoading}>
        {videoList && (
          <MemberViewVideoCatViewPage lang={lang} videoList={videoList} />
        )}
      </Spin>
    </>
  );
};

export default MemberViewVideoCat;
